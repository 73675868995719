@import "../../../scss/vars.scss";

.events-section {
  a {
    text-decoration: none;
    color: var(--bs-body-color);
  }

  #{$rtl} & {
    h2 {
      position: relative;
      margin-bottom: 3rem;

      span {
        font-size: 40px;
        color: #00927b;
        background-color: #fff;
        padding: 40px 15px 0;
        border-radius: 10px;
        margin-inline-start: 94px;
      }

      &::before {
        content: "";
        width: calc(100% - 94px);
        height: 13px;
        display: block;
        background: #efc420;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: -2;
      }

      &::after {
        content: "";
        width: 94px;
        height: 13px;
        display: block;
        background: #efc420;
        position: absolute;
        top: 50%;
        right: 0;
        z-index: -2;
      }
    }

    .row-ltr {
      flex-direction: row;
    }
  }
  #{$ltr} & {
    h2 {
      position: relative;
      margin-bottom: 3rem;
      direction: ltr;

      span {
        font-size: 40px;
        color: #00927b;
        background-color: #fff;
        padding: 40px 15px 0;
        border-radius: 10px;
        margin-inline-start: 94px;
      }

      &::before {
        content: "";
        width: 94px;
        height: 13px;
        display: block;
        background: #efc420;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: -2;
      }

      &::after {
        content: "";
        width: calc(100% - 94px);
        height: 13px;
        display: block;
        background: #efc420;
        position: absolute;
        top: 50%;
        right: 0;
        z-index: -2;
      }
    }

    .row-ltr {
      flex-direction: row-reverse;
    }
  }

  .right-side {
    .events {
      background-color: #e4e6e8;
      border-radius: 15px;
      margin-bottom: 18px;
      padding: 8px 0 40px;

      #{$rtl} & {
        .heading {
          display: flex;
          padding: 10px 19px;
          justify-content: space-between;
          align-items: center;

          p {
            margin: 0;

            &.label {
              color: #00927b;
              font-size: 20px;
              font-weight: 700;
              line-height: 16.5px;
            }

            &.pill {
              background-color: #00927b;
              padding: 10px;
              border-radius: 15px;
              color: #fff;
              font-size: 15px;
              font-weight: 400;

              span {
                font-weight: 700;
              }
            }
          }
        }

        .events-slider {
          padding: 10px 17px;

          .event-card__body {
            .event-card__img {
              width: 282px;
              height: 372px;
              margin: 0 auto;
              margin-bottom: 16px;

              img {
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            .event-card__text {
              width: 282px;
              margin: 0 auto;

              h6 {
                color: #00927b;
                font-size: 14px;
                direction: rtl;
              }

              p {
                color: #1c0a0a;
                font-size: 12px;
                margin: 0;
                direction: rtl;
              }
            }
          }

          .slick-dots {
            bottom: -30px;

            li {
              margin: 0 3px;

              button::before {
                color: #fff;
                font-size: 9px;
                opacity: 1;
              }

              &.slick-active button::before {
                color: #00927b;
                font-size: 15px;
              }
            }
          }
        }
      }
      #{$ltr} & {
        .heading {
          display: flex;
          flex-direction: row-reverse;
          padding: 10px 19px;
          justify-content: space-between;
          align-items: center;

          p {
            margin: 0;

            &.label {
              color: #00927b;
              font-size: 20px;
              font-weight: 700;
              line-height: 16.5px;
            }

            &.pill {
              background-color: #00927b;
              padding: 10px;
              border-radius: 15px;
              color: #fff;
              font-size: 15px;
              font-weight: 400;

              span {
                font-weight: 700;
              }
            }
          }
        }

        .events-slider {
          padding: 10px 17px;

          .event-card__body {
            .event-card__img {
              width: 282px;
              height: 372px;
              margin: 0 auto;
              margin-bottom: 16px;

              img {
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            .event-card__text {
              width: 282px;
              margin: 0 auto;

              h6 {
                color: #00927b;
                font-size: 14px;
                direction: ltr;
              }

              p {
                color: #1c0a0a;
                font-size: 12px;
                margin: 0;
                direction: ltr;
              }
            }
          }

          .slick-dots {
            bottom: -30px;

            li {
              margin: 0 3px;

              button::before {
                color: #fff;
                font-size: 9px;
                opacity: 1;
              }

              &.slick-active button::before {
                color: #00927b;
                font-size: 15px;
              }
            }
          }
        }
      }
    }

    .ads {
      background-color: #e4e6e8;
      border-radius: 15px;
      padding: 10px 30px 26px;

      #{$rtl} & {
        .heading {
          display: flex;
          padding: 10px;
          justify-content: space-between;
          align-items: center;

          p {
            margin: 0;

            &.label {
              color: #00927b;
              font-size: 20px;
              font-weight: 700;
            }

            &.pill {
              background-color: #00927b;
              padding: 10px;
              border-radius: 15px;
              color: #fff;
              font-size: 15px;
            }
          }
        }

        .ads-slider-container {
          background-color: #d9d9d9;
          padding: 10px;
          border-radius: 15px;

          .slider-inner {
            background-color: #fff;
            border-radius: 15px;
            padding: 7px 10px 30px;

            .ads-card {
              padding: 0 10px;

              .hashtag {
                direction: rtl;
                color: #00927b;
                margin: 0;
                // text-decoration: underline;
              }

              .ad-content {
                margin: 21px 0;
                padding: 10px;
                border: 2px solid #00927b;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                position: relative;

                img {
                  width: 377.333px !important;
                  height: 199px;
                }

                .date {
                  position: absolute;
                  bottom: -35px;
                  left: 25px;
                  border-radius: 10px;
                  background: #00927b;
                  padding: 10px;
                  color: #fff;
                  font-size: 12px;
                }
              }
            }

            .slick-dots {
              li {
                margin: 0 3px;

                button::before {
                  color: #d9d9d9;
                  font-size: 9px;
                  opacity: 1;
                }

                &.slick-active button::before {
                  color: #00927b;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
      #{$ltr} & {
        .heading {
          display: flex;
          padding: 10px;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;

          p {
            margin: 0;

            &.label {
              color: #00927b;
              font-size: 20px;
              font-weight: 700;
            }

            &.pill {
              background-color: #00927b;
              padding: 10px;
              border-radius: 15px;
              color: #fff;
              font-size: 15px;
            }
          }
        }

        .ads-slider-container {
          background-color: #d9d9d9;
          padding: 10px;
          border-radius: 15px;

          .slider-inner {
            background-color: #fff;
            border-radius: 15px;
            padding: 7px 10px 30px;

            .ads-card {
              padding: 0 10px;

              .hashtag {
                direction: rtl;
                color: #00927b;
                margin: 0;
                direction: ltr;
                // text-decoration: underline;
              }

              .ad-content {
                margin: 21px 0;
                padding: 10px;
                border: 2px solid #00927b;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                position: relative;

                img {
                  width: 377.333px !important;
                  height: 199px;
                }

                .date {
                  position: absolute;
                  bottom: -35px;
                  left: 25px;
                  border-radius: 10px;
                  background: #00927b;
                  padding: 10px;
                  color: #fff;
                  font-size: 12px;
                }
              }
            }

            .slick-dots {
              li {
                margin: 0 3px;

                button::before {
                  color: #d9d9d9;
                  font-size: 9px;
                  opacity: 1;
                }

                &.slick-active button::before {
                  color: #00927b;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  .left-side {
    .prints {
      padding: 20px 20px 40px;
      border-radius: 15px;
      background: #e4e6e8;
      margin-bottom: 40px;

      #{$rtl} & {
        .heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          p {
            margin: 0;

            &.label {
              color: #00927b;
              font-size: 20px;
              font-weight: 700;
              line-height: 16.5px;
            }

            &.pill {
              background-color: #00927b;
              padding: 10px;
              border-radius: 15px;
              color: #fff;
              font-size: 15px;
              font-weight: 400;

              span {
                font-weight: 700;
              }
            }
          }
        }
      }
      #{$ltr} & {
        .heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          flex-direction: row-reverse;

          p {
            margin: 0;

            &.label {
              color: #00927b;
              font-size: 20px;
              font-weight: 700;
              line-height: 16.5px;
            }

            &.pill {
              background-color: #00927b;
              padding: 10px;
              border-radius: 15px;
              color: #fff;
              font-size: 15px;
              font-weight: 400;

              span {
                font-weight: 700;
              }
            }
          }
        }
      }

      .prints-slider-container {
        background-color: #fff;
        border-radius: 15px;
        padding: 17px 10px 30px;
        height: 317px;
        display: flex;
        justify-content: center;
        align-items: center;

        .cards-holder {
          width: 100%;
          padding: 10px 18px;
          border-radius: 15px;
          border: 1px solid #e4e6e8;

          .slick-slide {
            overflow: hidden;
          }

          .print-card {
            position: relative;
            display: flex !important;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            padding: 18px 5px;
            border: 1px solid #e4e6e8;

            .whiteCard {
              border-radius: 15px;
              background: #eff2f9;
              width: 366px;
              height: 103px;
              padding: 11px 26px;
              position: absolute;
              right: -52px;
              top: 9.5px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              img {
                width: 30px;
                height: 30px;
              }

              p {
                margin: 0 10px 0 0;
                color: #00927b;
                font-size: 14px;
              }
            }

            .greenCard {
              width: 321px;
              height: 127px;
              border-radius: 10px;
              padding: 12px 37px 9px 7px;
              display: flex;
              align-items: flex-end;
              background: var(
                --gr,
                linear-gradient(90deg, #03a78e 0%, #75bc56 100%)
              );

              .printContent {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-end;

                p {
                  margin: 0;
                  color: #fff;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .slick-dots {
        bottom: -55px;

        li {
          margin: 0 3px;

          button::before {
            color: #d9d9d9;
            font-size: 9px;
            opacity: 1;
          }

          &.slick-active button::before {
            color: #00927b;
            font-size: 15px;
          }
        }
      }
    }

    .models {
      border-radius: 15px;
      background: #e4e6e8;
      padding: 10px 20px 20px;

      #{$rtl} & {
        .head {
          color: #00927b;
          padding: 10px;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 0.4rem;
        }

        .model-list {
          padding: 10px;
          background-color: #fff;
          border-radius: 15px;

          p {
            margin-bottom: 5px;
            font-size: 18px;
            line-height: 28.5px;
            padding: 10px;
            border-bottom: 1px solid #e4e6e8;
          }
        }
      }
      #{$ltr} & {
        .head {
          color: #00927b;
          padding: 10px;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 0.4rem;
          direction: ltr;
        }

        .model-list {
          padding: 10px;
          background-color: #fff;
          border-radius: 15px;

          p {
            margin-bottom: 5px;
            font-size: 18px;
            line-height: 28.5px;
            padding: 10px;
            border-bottom: 1px solid #e4e6e8;
            direction: ltr;
          }
        }
      }
    }
  }
}

.events-section .slick-arrow,
.events-section .slick-next:before,
.events-section .slick-prev:before {
  display: none !important;
}

@media screen and (max-width: 1400.5px) {
  .events-section {
    .right-side {
      .events {
        .events-slider {
          .event-card__body {
            .event-card__img {
              width: 250px;
              margin: 0 auto;
            }

            .event-card__text {
              width: 250px;
              margin: 0 auto;
            }
          }
        }
      }
    }

    .left-side {
      .models {
        .model-list {
          p {
            font-size: 16px;
            line-height: 1.3;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1240.5px) {
  .events-section {
    .right-side {
      .events {
        .events-slider {
          .event-card__body {
            height: 325px;

            .event-card__img {
              width: 230px;
              margin: 0 auto;
            }

            .event-card__text {
              width: 230px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024.5px) {
  .events-section {
    .left-side {
      .prints {
        .prints-slider-container {
          padding: 5px;

          .cards-holder {
            padding: 10px !important;

            .print-card {
              padding: 20px 5px 10px !important;

              .whiteCard {
                width: 85%;
                right: -15px;
              }

              .greenCard {
                width: 90%;
                padding: 12px 10px 9px 7px;
                height: 120px;

                .content {
                  p {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }

      .models {
        .model-list {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  .events-section {
    padding: 40px 0;

    h2 {
      text-align: center;

      span {
        margin: 0 !important;
        padding: 15px;
        font-size: 35px;
      }

      &::before {
        width: 50%;
      }

      &::after {
        width: 50%;
      }
    }

    .right-side {
      margin-bottom: 20px;

      .events {
        .events-slider {
          padding: 0;

          .event-card__body {
            padding: 10px;

            .event-card__img {
              width: 100%;
              height: initial;
            }

            .event-card__text {
              width: 100%;
              margin-top: 10px;

              h6 {
                font-size: 18px;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }

      .ads {
        padding: 8px 10px 40px;

        .ads-slider-container {
          .slider-inner {
            padding: 10px 10px 40px;

            .ads-card {
              .ad-content {
                padding: 10px 10px 35px;

                img {
                  width: 100% !important;
                }
              }
            }

            .slick-dots {
              bottom: -30px;
            }
          }
        }
      }
    }

    .left-side {
      .prints {
        margin-bottom: 20px;

        .prints-slider-container {
          height: initial;
          padding: 10px 10px 40px;

          .cards-holder {
            padding: 10px;

            .print-card {
              padding: 25px 10px 10px;

              .whiteCard {
                width: 300px;
                right: -70px;

                img {
                  margin-inline-end: 30px;
                }
              }

              .greenCard {
                padding: 10px 20px 10px 10px;
                margin-bottom: 0;
              }
            }
          }
        }
        .slick-dots {
          bottom: -45px;
        }
      }

      .models {
        .model-list {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
