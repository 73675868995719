@import "../../../scss/vars.scss";

.About-container {
  padding: 60px 77px 12rem;

  .innerContent {
    width: 100%;
    border-radius: 50px;
    padding: 0 83px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    h2 {
      font-size: 48px;
      font-weight: 500;
      margin: 0;
      text-align: center;
    }
    hr {
      border-top: 6px solid;
      width: 130px;
      opacity: 1;
      margin: 70px 0;
    }
    p {
      font-size: 22px;
      font-weight: 500;

      #{$ltr} & {
        direction: ltr;
      }
    }
  }

  .vision {
    height: 556px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 15rem;
    .innerContent {
      background-color: #cae7e1;
      height: 380px;
      align-items: flex-end;

      .rightSide {
        .imgHolder {
          width: 550px;
          img {
            width: 100%;
            border: 8px solid #cae7e1;
            border-radius: 64px 64px 0px 0px;
            padding: 35px 30px 45px;
          }
        }
      }
      .leftSide {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 80px 75px;

        hr {
          border-color: #00927b;
        }
        p {
          text-align: center;
          line-height: 60px;
        }
      }
    }
  }
  .message {
    margin-bottom: 17rem;

    .innerContent {
      background-color: #f8edcb;
      height: 480px;
      align-items: flex-end;

      .rightSide {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 0 80px;

        hr {
          border-color: #f8c83b;
        }
        p {
          line-height: 50px;
        }
      }
      .leftSide {
        .imgHolder {
          width: 550px;
          img {
            width: 100%;
            border: 8px solid #f8edcb;
            border-radius: 64px 64px 0px 0px;
            padding: 35px 30px 55px;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  .goals {
    .innerContent {
      flex-direction: column;
      background-color: #cee2e5;

      .bi-check-circle-fill {
        margin-inline-end: 10px;
        fill: #31a2c7;
      }

      .sides {
        height: 380px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .rightSide {
          margin-bottom: 3rem;
          .imgHolder {
            width: 550px;

            img {
              width: 100%;
              bottom: 0;
              border: 8px solid #cee2e5;
              border-radius: 64px 64px 0px 0px;
              padding: 35px 30px 55px;
            }
          }
        }
        .leftSide {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 80px 45px;

          hr {
            border-color: #31a2c7;
          }
          p {
            line-height: 71px;
          }
        }
      }
      .wideSection {
        p {
          display: flex;
          align-items: flex-start;
          font-size: 24px;
          line-height: 71px;

          svg {
            width: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1440.5px) {
    .innerContent {
      p {
        font-size: 20px;
      }
    }

    .vision {
      .innerContent {
        justify-content: space-between;
        .rightSide {
          .imgHolder {
            width: 500px;
            img {
              width: 100%;
            }
          }
        }
        .leftSide {
          width: 50%;
          padding: 0 10px 80px;
        }
      }
    }
    .message {
      .innerContent {
        justify-content: space-between;
        .leftSide {
          padding-bottom: 3rem;
          .imgHolder {
            width: 500px;
            img {
              width: 100%;
            }
          }
        }
        .rightSide {
          padding: 0 40px;
          p {
            line-height: 45px;
          }
        }
      }
    }
    .goals {
      .innerContent {
        .sides {
          justify-content: space-between;
          .rightSide {
            .imgHolder {
              width: 500px;
              img {
                width: 100%;
              }
            }
          }
          .leftSide {
            padding: 0 9px 40px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024.5px) {
    padding: 60px 30px;
    .innerContent {
      padding: 0 65px;
    }
    .vision {
      .innerContent {
        .rightSide {
          .imgHolder {
            width: 400px;
            img {
              padding: 35px 10px 90px;
            }
          }
        }
        .leftSide {
          padding: 0 0 80px;
          p {
            font-size: 19px;
            line-height: 40px;
          }
        }
      }
    }
    .message {
      .innerContent {
        justify-content: space-between;
        .leftSide {
          .imgHolder {
            width: 380px;
            img {
              padding: 35px 10px 90px;
            }
          }
        }
        .rightSide {
          padding: 0 0 0 20px;
          p {
            font-size: 19px;
            line-height: 40px;
          }
        }
      }
    }
    .goals {
      .innerContent {
        justify-content: space-between;
        .sides {
          .rightSide {
            .imgHolder {
              width: 400px;
              img {
                padding: 35px 10px 90px;
              }
            }
          }
          .leftSide {
            p {
              line-height: 50px;
            }
          }
        }
        .wideSection {
          p {
            font-size: 20px;
            line-height: 50px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .innerContent {
      flex-direction: column;
    }
    .vision {
      height: fit-content;
      align-items: center;
      margin-bottom: 5rem;
      .innerContent {
        height: fit-content;
        .rightSide {
          .imgHolder {
            width: 80%;
            margin: 0 auto;
            img {
              border: none;
              padding: 20px;
            }
          }
        }
        .leftSide {
          width: 100%;
        }
      }
    }
    .message {
      height: fit-content;
      align-items: center;
      margin-bottom: 5rem;

      .innerContent {
        flex-direction: column-reverse;
        height: fit-content;
        .leftSide {
          padding-bottom: 0;
          .imgHolder {
            width: 80%;
            margin: 0 auto;
            img {
              border: none;
              padding: 20px;
              margin-bottom: 0;
            }
          }
        }
        .rightSide {
          width: 100%;
          padding-bottom: 80px;
        }
      }
    }
    .goals {
      .innerContent {
        .sides {
          height: fit-content;
          flex-direction: column;
          .rightSide {
            .imgHolder {
              width: 80%;
              margin: 0 auto;
              img {
                border: none;
                padding: 20px;
                margin-bottom: 0;
              }
            }
          }
          .leftSide {
            width: 100%;
            padding: 20px 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 575.5px) {
    .innerContent {
      padding: 0 35px;
    }

    .vision {
      .innerContent {
        .rightSide {
          .imgHolder {
            width: 100%;
          }
        }
        .leftSide {
          p {
            font-size: 16px;
            text-align: justify;
          }
        }
      }
    }
    .message {
      .innerContent {
        .leftSide {
          .imgHolder {
            width: 100%;
          }
        }
        .rightSide {
          padding: 0 0 80px 0;
          p {
            font-size: 16px;
            text-align: justify;
          }
        }
      }
    }
    .goals {
      .innerContent {
        .sides {
          .rightSide {
            .imgHolder {
              width: 100%;
            }
          }
          .leftSide {
            p {
              text-align: justify;
              font-size: 16px;
              line-height: 40px;
            }
          }
        }
        .wideSection {
          p {
            font-size: 16px;
            line-height: 40px;
            text-align: justify;
          }
        }
      }
    }
  }
}
