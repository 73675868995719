@import "../../../scss/vars.scss";

.laws {
  padding-bottom: 10rem;
}

.laws-box {
  width: 85%;
  height: 230px;
  border-radius: 25px;
  position: relative;
  display: flex;
  margin: auto auto 30px;

  #{$rtl} & {
    flex-direction: row;
    .info {
      width: 50%;
      background-color: #05555c;
      position: relative;
      border-radius: 0 25px 25px 0;
      padding: 50px 20px;

      h3 {
        color: #fff;
        font-size: 22px;
      }

      button {
        width: 120px;
        padding: 5px 10px;
        background-color: #00bd9f;
        border-radius: 50px;
        position: absolute;
        bottom: 30px;
        right: -50px;
        color: #fff;
      }
    }

    .img {
      width: 50%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 25px 0 0 25px;
      }
    }
  }

  #{$ltr} & {
    flex-direction: row-reverse;

    .info {
      width: 50%;
      background-color: #05555c;
      position: relative;
      border-radius: 25px 0 0 25px;
      padding: 50px 20px;

      h3 {
        color: #fff;
        font-size: 22px;
        direction: ltr;
      }

      button {
        width: 120px;
        padding: 5px 10px;
        background-color: #00bd9f;
        border-radius: 50px;
        position: absolute;
        bottom: 30px;
        left: -50px;
        color: #fff;
      }
    }

    .img {
      width: 50%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 0 25px 25px 0;
      }
    }
  }
}
