@import "../../../scss/vars.scss";

a {
  text-decoration: unset !important;
}

.competitions-container {
  background-color: #e6e6e6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 383px;
  padding: 74px 10px;
  .inner-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 215px;

    #{$ltr} & {
      flex-direction: row-reverse;
    }

    .main-card {
      display: flex;
      width: 210px;
      height: 100%;
      padding: 2px 29px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 17px;
      border: 1px solid #00927b;
      background: rgba(196, 196, 196, 0.49);
      margin: 0 2rem 0 4rem;

      #{$ltr} & {
        text-align: left;
      }

      .img-holder {
        width: 62px;
        height: 62px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 20px;
      }
      p {
        color: #00927b;
        margin: 0;
        font-weight: 700;
        font-size: 18px !important;
      }
    }
    .slider-container {
      width: 60% !important;
      margin: 10px auto;
      position: relative;

      .comp-card {
        width: 170px !important;
        height: 215px;
        padding-top: 31px;
        margin: 0 auto;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        background: url("../../../Assets/Homepage/Competitions/PolygonWhite.svg")
          no-repeat center;
        background-position: bottom;
        background-size: contain;
        background-color: #fff;
        border: none;

        img {
          width: 40px;
          margin-bottom: 20px;
        }
        p {
          color: #a5a6aa;
          text-align: center;
        }

        &:hover {
          background: url("../../../Assets/Homepage/Competitions/Polygon.svg")
            no-repeat center;
          background-position: bottom;
          background-size: contain;
          border: 1px solid #00927b;
          background-color: #fff;

          svg {
            path {
              fill: #00927b;
            }
          }
          p {
            color: #00927b;
          }
        }
      }
    }
  }
}

.slick-prev:before {
  margin-left: -11px;//-40px;
  content: url("../../../Assets//Homepage/Competitions//arrowright.svg") !important;
  background-color: #00927b !important;
  display: block;
  line-height: 1.75 !important;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.slick-next {
  right: -40px;
}

.slick-next:before {
  margin-right: 20px;
  background-color: #00927b !important;
  display: block;
  line-height: 1.75 !important;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  content: url("../../../Assets//Homepage/Competitions//arrowleft.svg") !important;
}

@media screen and (max-width: 1440.5px) {
  .competitions-container {
    .inner-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1280.5px) {
  .competitions-container {
    .inner-container {
      .main-card {
        margin: 0 1rem;
      }
      .slider-container {
        width: 65% !important;
        margin: 0 auto;
        .slick-prev:before {
          margin-left: -15px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1024.5px) {
  .competitions-container {
    .inner-container {
      .main-card {
        margin: 0 1rem;
      }
      .slider-container {
        width: 55% !important;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  .competitions-container {
    padding: 40px 20px;
    height: fit-content;
    .inner-container {
      height: fit-content;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .main-card {
        height: 100px;
        margin: 0 0 2rem 0;
        border: none;
        background-color: #e6e6e6;
      }
      .slider-container {
        margin: 0;
        width: 100%;
        .comp-card {
          margin: 0 auto;
        }

        .slick-prev{
          right: -40px;
        }
        .slick-next{
          left: -40px;
        }
      }
    }
  }
}
