@import "../../scss/vars.scss";

$main-color: #00927b;
$secondary-color: #4e6276;

.navbar-container {
  padding-top: 17px;
  position: relative;

  .main-nav {
    padding: 0 77px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;

    #{$rtl} & {
      flex-direction: row;

      .nav__list-container {
        width: 74.5%;
        padding: 10px 10px 0 0;

        .navbar {
          padding: 0 !important;

          a {
            text-decoration: none;
            margin-inline-end: 5px;

            &.active {
              .nav-link {
                background-color: #60b0a0;

                span {
                  color: #fff;
                }

                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
            }
          }

          .nav-link {
            padding: 10px !important;
            margin: 0;
            cursor: pointer;
            border-radius: 15px 15px 0 0;

            span {
              font-size: 15px !important;
              font-weight: 500;
              margin: 0 !important;
              color: $secondary-color;
            }

            svg {
              width: 16px;
              height: 16px;
              margin-inline-end: 10px;
            }

            &:not(:last-of-type) {
              margin-inline-end: 15px;
            }

            &.active,
            &.activeRoute {
              background-color: #60b0a0;

              span {
                color: #fff;
              }

              svg {
                path {
                  stroke: #fff;
                }
              }
            }
          }
        }
      }

      .nav__actions-container {
        width: 20%;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .socials {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 25%;
          margin-inline-end: 19px;

          svg {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            fill: #fff;
            background-color: $main-color;

            &:not(.youtube) {
              padding: 2.5px;
            }
          }
        }

        .language {
          cursor: pointer;
          span {
            font-size: 14px;
            color: $secondary-color;
            margin-inline-start: 6px;
          }
        }
      }
    }

    #{$ltr} & {
      flex-direction: row-reverse;

      .nav__list-container {
        width: 74.5%;
        padding: 10px 0 0 10px;

        .navbar {
          padding: 0 !important;
          justify-content: flex-end;

          .navbar-nav {
            flex-direction: row-reverse;
          }

          a {
            text-decoration: none;
            margin-inline-start: 5px;

            &.active {
              .nav-link {
                background-color: #60b0a0;

                span {
                  color: #fff;
                }

                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
            }
          }

          .nav-link {
            padding: 10px !important;
            direction: ltr;
            margin: 0;
            cursor: pointer;
            border-radius: 15px 15px 0 0;

            span {
              font-size: 15px !important;
              font-weight: 500;
              margin: 0 !important;
              color: $secondary-color;
            }

            svg {
              width: 16px;
              height: 16px;
              margin-inline-end: 10px;
            }

            &:not(:last-of-type) {
              margin-inline-start: 15px;
            }

            &.active,
            &.activeRoute {
              background-color: #60b0a0;

              span {
                color: #fff;
              }

              svg {
                path {
                  stroke: #fff;
                }
              }
            }
          }
        }
      }

      .nav__actions-container {
        width: 20%;
        padding: 10px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        .socials {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 25%;
          margin-inline-start: 19px;

          svg {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            fill: #fff;
            background-color: $main-color;

            &:not(.youtube) {
              padding: 2.5px;
            }
          }
        }

        .language {
          direction: ltr;
          cursor: pointer;
          span {
            font-size: 14px;
            color: $secondary-color;
            margin-inline-start: 6px;
          }
        }
      }
    }
  }

  .sub-nav {
    background: #60b0a0;
    padding: 3px 76px;
    height: 70px;

    ul {
      list-style: none;
      height: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;

      #{$rtl} & {
        flex-direction: row;

        li {
          cursor: pointer;
          width: fit-content;

          &:not(:last-of-type) {
            margin-inline-end: 10px;
          }

          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;
            font-size: 13px;
            font-weight: 700;
            color: #fff;
            text-decoration: none;
            width: fit-content;

            &.active {
              // border: 2px solid #fff;
              // border-radius: 10px;
              span {
                background-color: #fff;
                padding: 3px 8px;
                border-radius: 15px;
                color: #00927b;
              }
            }
          }

          img {
            margin-inline-end: 12px;
            width: 30px;
          }
        }
      }
      #{$ltr} & {
        flex-direction: row-reverse;

        li {
          cursor: pointer;
          width: fit-content;

          &:not(:last-of-type) {
            margin-inline-end: 10px;
          }

          a {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding: 10px;
            font-size: 13px;
            font-weight: 700;
            color: #fff;
            text-decoration: none;
            width: fit-content;

            &.active {
              // border: 2px solid #fff;
              // border-radius: 10px;
              span {
                background-color: #fff;
                padding: 3px 8px;
                border-radius: 15px;
                color: #00927b;
              }
            }
          }

          img {
            margin-inline-start: 12px;
            width: 30px;
          }
        }
      }
    }
  }

  .mobileNav {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    z-index: 999;
    padding: 10px 10px 0;

    .close-menu {
      position: absolute;
      width: 30px;
      top: 10px;
      cursor: pointer;
      #{$rtl} & {
        left: 10px;
      }
      #{$ltr} & {
        right: 10px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
    }

    span {
      color: #00927b;
    }

    .main-section {
      display: flex;
      padding: 10px 5px 10px 0;

      #{$rtl} & {
        flex-direction: row;
      }
      #{$ltr} & {
        flex-direction: row-reverse;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid #b3bcc9;
      }

      .nav-link {
        margin: 0;
        width: 50%;
        text-align: start;
        #{$rtl} & {
          direction: rtl;
        }
        #{$ltr} & {
          direction: ltr;
        }

        svg {
          path {
            stroke: #00927b;
          }
        }

        span {
          font-size: 16px;
        }
      }

      .sub-section {
        width: 50%;

        #{$rtl} & {
          direction: rtl;
        }
        #{$ltr} & {
          direction: ltr;
        }

        li {
          margin-bottom: 5px;
          text-align: start;

          img {
            margin-inline-end: 5px;
            width: 20px;
          }

          span {
            font-size: 14px;

            &.text-only {
              margin-inline-start: 25px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .navbar-container {
    .sub-nav {
      ul {
        li {
          font-size: 14px;
          padding: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  .navbar-container {
    .main-nav {
      .nav__list-container {
        width: 70%;
        padding: 0;

        .navbar {
          .nav-link {
            padding: 10px 5px !important;

            span {
              font-size: 14px !important;
            }
          }
        }
      }

      .nav__actions-container {
        width: 25%;
      }
    }

    .sub-nav {
      ul {
        li {
          font-size: 12px;
          padding: 5px;

          img {
            margin-inline-end: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1120px) {
  .navbar-container {
    .main-nav {
      padding: 0 30px;
    }

    .sub-nav {
      height: 60px;
    }
  }
}

@media screen and (max-width: 1024.5px) {
  .navbar-container {
    .main-nav {
      padding: 0 30px;

      .nav__list-container {
        width: 75%;
      }

      .nav__actions-container {
        width: 25%;
      }
    }

    .sub-nav {
      padding: 0 45px;
    }
  }
}

@media screen and (max-width: 991.5px) {
  .navbar-container {
    .main-nav {
      .nav__list-container {
        .navbar {
          .navbar-nav {
            flex-direction: row;

            .nav-link {
              &:not(:last-of-type) {
                margin-inline-end: 10px !important;
              }

              svg {
                margin-inline-end: 5px;
              }

              span {
                font-size: 12px !important;
              }
            }
          }
        }
      }

      .nav__actions-container {
        width: 30%;
      }
    }

    .sub-nav {
      ul {
        li {
          font-size: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  .navbar-container {
    padding-top: 0;

    .main-nav {
      display: none;
    }

    .sub-nav {
      display: none;
    }
  }
}

.nav-link {
  a {
    text-decoration: none;
  }
}
.sub-section {
  li {
    a {
      text-decoration: none;
    }
  }
}
