.terms-conditions {
    .content {
      background-color: #f7f7f5;
      padding: 60px 50px;
  
      .data {
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 12px;
        box-shadow: 0px 0px 8px 0 #d4d2d2;
        padding: 60px 25px;
        position: relative;
  
        img {
          position: absolute;
          left: 25px;
          top: -40px;
          width: 100px;
          box-shadow: 0px 0px 8px 0 #adb5bd;
          background: #fff;
          padding: 10px;
          border-radius: 10px;
        }
  
        .item {
          margin-bottom: 20px;
          padding-bottom: 10px;
          border-bottom: 2px solid #dee2e6;
          text-align: right;
          &:last-child {
            border-bottom: 0;
          }
  
          h3 {
            color: #009f7c;
            font-size: 16px;
            font-family: "Alexandria", sans-serif !important;
            margin-bottom: 20px;
          }
  
          p {
            font-size: 15px;
            font-family: "Alexandria", sans-serif !important;
            color: #7b848e;
          }
        }
      }
    }
  }