@import "../../../scss/vars.scss";

.matchDetails {
  margin-bottom: 4rem;

  #{$rtl} & {
    direction: rtl;
  }
  #{$ltr} & {
    direction: ltr;
  }

  .matchSport {
    padding: 6px 0px;
    margin: 18px 0;

    .sport {
      width: fit-content;
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 15px;
      background: #eee;

      img {
        width: 20px;
        height: 20px;
        margin-inline-end: 10px;
      }

      p {
        margin: 0;
        color: #00927b;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .teams {
    border-radius: 15px;
    border: 1px solid #000;
    background: #fff;
    padding: 10px;

    .innerBox {
      border-radius: 15px;
      background: #00927b;
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .team_container {
        border-radius: 15px;
        background: #fff;
        padding: 14px 28px;
        flex: 1;

        &:first-of-type {
          margin-inline-end: 10px;
        }

        .teamHeading {
          flex: 1;
          border-radius: 15px;
          background: #fff;
          padding: 11px 12px;
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;

          .teamInfo {
            flex: 3;
            display: flex;
            justify-content: center;
            align-items: center;

            .teamImg {
              border-radius: 50%;
              width: 52px;
              height: 51px;
              display: flex;
              justify-content: center;
              align-items: center;

              &.greenBorder {
                border: 2px solid #00927b;
              }

              &.yellowBorder {
                border: 2px solid #efc420;
              }

              img {
                width: 30px;
              }
            }

            p {
              text-align: center;
              font-size: 12px;
              padding: 10px;
              margin: 0;
            }
          }

          .sideScore {
            flex: 1;
            border-radius: 15px;
            background: #f2f3f4;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              margin: 0;
              font-size: 30px;
            }
          }

          &.team2 {
            .teamInfo {
              flex-direction: row;
            }

            .siideScore {
              margin-inline-end: 10px;
            }
          }

          &:first-of-type {
            margin-inline-end: 10px;
          }
        }

        .teamList {
          padding: 10px 20px;
          max-height: 330px;
          overflow-y: scroll;

          #{$rtl} & {
            direction: rtl;
          }
          #{$ltr} & {
            direction: ltr;
          }

          th {
            text-align: center;
            font-size: 14px;
            font-weight: 500;

            &.playerName {
              color: #7a869a;
              font-weight: 700;
            }
          }

          td {
            text-align: center;
            padding: 10px;

            img {
              width: 32px;
              height: 32px;
              border-radius: 32px;
            }

            &.playerName {
              color: #7a869a;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .eventsLabel {
    padding: 10px;
    margin: 10px 0;

    img {
      width: 30px;
      height: 30px;
      margin-inline-end: 10px;
    }

    font-weight: 700;
  }

  .matchEvents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .events_rightSide {
      flex: 1;
      margin-inline-end: 43px;
      max-height: 400px;
      overflow-y: scroll;
      padding-inline-end: 10px;

      .eventCard {
        border-radius: 15px;
        background: #fff;
        padding: 10px;
        margin-bottom: 10px;

        &_top {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;

          .player {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
              width: 50px;
              height: 50px;
              border-radius: 50px;
              margin-inline-end: 21px;
            }

            p {
              margin: 0;
              font-size: 15px;
              font-weight: 500;
            }
          }

          .team {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
              font-size: 12px;
              font-weight: 400;
              margin-inline-end: 7px;
              margin-bottom: 0;
            }

            .teamImg {
              border-radius: 50%;
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 12px;
              }

              &.greenBorder {
                border: 2px solid #00927b;
              }

              &.yellowBorder {
                border: 2px solid #efc420;
              }
            }
          }
        }

        &_bottom {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .time {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
              margin-bottom: 0;
              color: #696969;
              margin-inline-end: 20px;
            }

            img {
              width: 18px;
              height: 18px;
            }
          }

          .type {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
              margin-bottom: 0;
              margin-inline-end: 7px;

              &.penalty {
                color: #696969;
              }
            }

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .events_leftSide {
      flex: 1;
      height: 345px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow-y: scroll;
      position: relative;

      .timeline {
        width: 100%;
        height: fit-content;
        position: relative;

        #{$rtl} & {
          padding: 40px 0 0 10px;
        }
        #{$ltr} & {
          padding: 40px 10px 0 0;
        }

        .split {
          z-index: 0;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          padding-inline-end: 10px;

          .splitIcon {
            z-index: 2;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            border-radius: 50px;
            background: linear-gradient(278deg, #efc420 0%, #00927b 100%);

            img {
              width: 20px;
            }
          }

          &::after {
            z-index: 1;
            position: absolute;
            content: "";
            display: block;
            height: 100%;
            width: 1px;
            background-color: #979797;
          }
        }

        .minute_container {
          z-index: 6;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;

          .step {
            flex: 1;
            display: flex;
            align-items: center;
            position: relative;

            .event {
              width: 80%;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              border-radius: 15px;
              border: 1px solid;
              background: #fff;
              padding: 15px 10px;
              z-index: 3;

              p {
                margin: 0;

                &.name {
                  position: absolute;

                  top: -10px;
                  z-index: 5;
                  border-radius: 15px;
                  font-size: 10px;
                  padding: 3px 10px;
                }
              }
            }

            .minute {
              position: absolute;
              z-index: 5;
              margin: 0;
              border-radius: 50px;
              border: 1px solid;
              background: #e1e2e4;
              padding: 5px;
            }

            &.active::after {
              position: absolute;
              content: "";
              width: 100%;
              height: 1px;
              z-index: 1;
            }

            &_right {
              justify-content: flex-start;

              .event {
                border-color: #efc420;
                justify-content: flex-end;

                p.eventLabel {
                  margin-inline-end: 10px;
                }

                p.name {
                  #{$rtl} & {
                    right: 5px;
                  }
                  #{$ltr} & {
                    left: 5px;
                  }
                  background-color: #efc420;

                  @media screen and (max-width: 575.5px) {
                    font-size: 9px;
                  }
                }
              }

              .minute {
                border-color: #efc420;
                #{$rtl} & {
                  left: -16px;
                }
                #{$ltr} & {
                  right: -16px;
                }
              }

              &::after {
                background-color: #efc420;
              }
            }

            &_left {
              flex-direction: row-reverse;

              .event {
                border-color: #00927b;
                flex-direction: row-reverse;
                justify-content: flex-end;

                p.eventLabel {
                  margin-inline-start: 10px;
                }

                p.name {
                  #{$rtl} & {
                    left: 5px;
                  }
                  #{$ltr} & {
                    right: 5px;
                  }
                  background-color: #00927b;
                }

                @media screen and (max-width: 575.5px) {
                  font-size: 9px;
                }
              }

              .minute {
                border-color: #00927b;
                #{$rtl} & {
                  right: -16px;
                }
                #{$ltr} & {
                  left: -16px;
                }
              }

              &::after {
                background-color: #00927b;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1440.5px) {
    .matchEvents {
      .events_rightSide {
        margin-bottom: 30px;
        margin-inline-end: 10px;
      }

      .events_leftSide {
        .timeline {
          .minute_container {
            .step {
              .event {
                padding: 15px 5px;

                .eventLabel {
                  font-size: 12px;
                }
              }

              &_right {
                .event {
                  &::after {
                    left: -49px;
                  }
                }
              }

              &_left {
                .event {
                  &::after {
                    right: -48px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .teams {
      .innerBox {
        .team_container {
          .teamHeading {
            .teamInfo {
              margin-inline-end: 15px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024.5px) {
    .teams {
      .innerBox {
        flex-direction: column;

        .team_container {
          &:first-of-type {
            margin-inline-end: 0;
            margin-bottom: 10px;
          }
        }
      }
    }

    .matchEvents {
      flex-direction: column;

      .events_rightSide {
        margin-inline-end: 0;
        margin-bottom: 30px;
      }

      .events_leftSide {
        .timeline {
          .minute_container {
            .step {
              &_right {
                .event {
                  &::after {
                    left: -53px;
                  }
                }
              }

              &_left {
                .event {
                  &::after {
                    right: -53px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768.5px) {
    margin-bottom: 0;

    .teams {
      .innerBox {
        .team_container {
          padding: 14px;

          .teamHeading {
            justify-content: space-between;

            .team1 {
              .teamInfo {
                margin-inline-end: 5px;
              }
            }

            .teamInfo {
              flex: initial;
              width: 75%;

              p {
                font-size: 11px;
                padding: 10px 5px;
              }

              .teamImg {
                width: 35px;
                height: 35px;

                img {
                  width: 10px;
                }
              }
            }

            .sideScore {
              flex: initial;
              width: 20%;
            }

            &:first-of-type {
              margin-inline-end: 0;
            }
          }
        }
      }
    }

    .matchEvents {
      .events_leftSide {
        .timeline {
          .minute_container {
            .step {
              .event {
                .eventLabel {
                  font-size: 12px;
                }
              }

              &_right {
                .event {
                  &::after {
                    left: -38px;
                  }
                }
              }

              &_left {
                .event {
                  &::after {
                    right: -37px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.emptyData {
  img {
    width: 20%;
  }
}
