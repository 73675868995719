@import "../../scss/vars.scss";

footer {
  background-color: #00927b;
  // margin-top: 6rem;
  height: 341px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .footer-section {
    width: 90%;
    margin: 0 auto;
    height: 378px;
    padding: 10px 11px;
    justify-content: center;
    align-items: center;
    background-color: #2d2d34;
    border-radius: 50px;
    position: absolute;
    top: -5.5rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    .footer-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 2rem 0 0;

      #{$rtl} & {
        flex-direction: row;

        .left-col {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          height: 100%;

          padding: 10px;
          margin-inline-end: 10px;

          .img-holder {
            margin-bottom: 16px;

            img {
              width: 200px;
            }
          }

          .socials {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 22%;
            // margin-inline-end: 19px;

            svg {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              fill: #2d2d34;
              background-color: #fff;

              &:not(.youtube) {
                padding: 2.5px;
              }
            }
          }
        }

        .mid-col {
          margin-inline-end: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 10px;
          height: 100%;

          h4 {
            color: #818183;
            font-size: 19px;
            margin-bottom: 20px;
          }

          .contact-info {
            display: flex;
            margin-bottom: 20px;
            color: #fff;

            i {
              margin-inline-end: 17px;
            }

            p {
              font-size: 13px;
              margin: 0;

              a {
                text-decoration: underline;
                color: #fff;
                cursor: pointer;
              }
            }
          }
        }

        .right-col {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 10px;
          height: 100%;

          h4 {
            color: #818183;
            font-size: 19px;
            margin-bottom: 20px;
          }

          .links {
            font-size: 13px;
            margin-bottom: 20px;

            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
      #{$ltr} & {
        flex-direction: row-reverse;

        .left-col {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          height: 100%;

          padding: 10px;
          margin-inline-end: 10px;

          .img-holder {
            margin-bottom: 16px;

            img {
              width: 200px;
            }
          }

          p {
            direction: ltr;
          }

          .socials {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 22%;
            // margin-inline-end: 19px;

            svg {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              fill: #2d2d34;
              background-color: #fff;

              &:not(.youtube) {
                padding: 2.5px;
              }
            }
          }
        }

        .mid-col {
          margin-inline-end: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 10px;
          height: 100%;

          h4 {
            color: #818183;
            font-size: 19px;
            margin-bottom: 20px;
            direction: ltr;
          }

          .contact-info {
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 20px;
            color: #fff;

            i {
              margin-inline-start: 17px;
            }

            p {
              font-size: 13px;
              margin: 0;
              direction: ltr;

              a {
                text-decoration: underline;
                color: #fff;
                cursor: pointer;
              }
            }
          }
        }

        .right-col {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 10px;
          height: 100%;

          h4 {
            color: #818183;
            font-size: 19px;
            margin-bottom: 20px;
            direction: ltr;
          }

          .links {
            font-size: 13px;
            margin-bottom: 20px;
            direction: ltr;

            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .copyrights {
    color: #fff;
    font-size: 16px;
    margin-bottom: 16px;
    span {
      #{$ltr} & {
        direction: ltr;
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  footer {
    #{$ltr} & {
      height: initial;
      margin-top: 0;
      flex-direction: column;
      padding: 20px;
  
      .footer-section {
        width: 100%;
        height: initial;
        position: static;
        padding: 0;
        top: 0;
  
        .footer-content {
          flex-direction: column;
          padding: 1rem 0;
  
          .left-col {
            width: 100%;
            margin: 0 0 20px 0;
          }
  
          .mid-col {
            width: 100%;
            margin: 0;
  
            .contact-info {
              width: 100%;
  
              i {
                margin-inline-end: 14px;
              }
  
              p {
                font-size: 12px;
              }
            }
          }
  
          .right-col {
            width: 100%;
          }
        }
      }
  
      .copyrights {
        text-align: center;
        font-size: 14px;
        margin: 10px 0 0 0;
      }
    }
    #{$rtl} & {
      height: initial;
      margin-top: 0;
      flex-direction: column;
      padding: 20px;
  
      .footer-section {
        width: 100%;
        height: initial;
        position: static;
        padding: 0;
        top: 0;
  
        .footer-content {
          flex-direction: column;
          padding: 1rem 0;
  
          .left-col {
            width: 100%;
            margin: 0 0 20px 0;
          }
  
          .mid-col {
            width: 100%;
            margin: 0;
  
            .contact-info {
              width: 100%;
  
              i {
                margin-inline-end: 14px;
              }
  
              p {
                font-size: 12px;
              }
            }
          }
  
          .right-col {
            width: 100%;
          }
        }
      }
  
      .copyrights {
        text-align: center;
        font-size: 14px;
        margin: 10px 0 0 0;
      }
    }

  }
}
