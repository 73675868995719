@import "../../scss/vars.scss";

.slick-slide img {
  width: 30px;
}

// empty data styling update

.emptyData {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 15px;
  }

  span {
    background-color: #00927b;
    padding: 8px 15px;
    color: #fff;
    border-radius: 10px;
  }
}

.competitions__container {
  padding-bottom: 10rem;
  background-color: #f0ebe3;

  .competitions_heading {
    height: 231px;
    padding: 0 56px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    img {
      &.competitions_headingImg {
        height: 100%;
        width: 80%;
        object-fit: cover;

        filter: grayscale(0.8);
      }

      &.headingIcon {
        z-index: 5;
        margin-inline-end: 5rem;
      }
    }

    .overlay {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          312deg,
          #d3e9e4 0%,
          rgba(228, 236, 249, 0) 56.06%,
          #effffc 92.89%
        ),
        rgb(211, 211, 211, 0.3);
    }
  }

  .main {
    padding: 0 40px;

    .competition-row {
      #{$rtl} & {
        flex-direction: row;
      }
      #{$ltr} & {
        flex-direction: row-reverse;
      }
    }

    background: linear-gradient(180deg, #fff 0%, #f0ebe3 100%);

    .rightSide {
      padding: 81px 30px 76px 19px;

      // right side filters block
      .filters {
        border-radius: 8px;
        border: 1px solid #ebebeb;
        background: #fff;
        padding: 31px;
        margin-bottom: 22px;

        .dropdown {
          margin-bottom: 16px;

          .btn {
            width: 100%;
            border-radius: 8px;
            border: 1px solid #ebebeb;
            background: #fff;
            padding: 18px 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            #{$rtl} & {
              flex-direction: row;

              .label {
                display: flex;
                align-items: center;
                flex-direction: row;

                img {
                  margin-inline-end: 12px;
                  width: 20px;
                  height: 20px;
                }

                p {
                  margin: 0;
                }
              }
            }
            #{$ltr} & {
              flex-direction: row-reverse;

              .label {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                img {
                  margin-inline-start: 12px;
                  width: 20px;
                  height: 20px;
                }

                p {
                  margin: 0;
                }
              }
            }

            svg {
              width: 20px;
              height: 16.8px;
            }
          }

          .dropdown-menu {
            width: 100%;

            .dropdown-item {
              text-align: right;
              font-size: 12px;
              #{$ltr} & {
                text-align: left;
              }
            }
          }
        }

        .datePicker {
          width: 100%;
          border-radius: 8px;
          border: 1px solid #ebebeb;
          background: #fff;
          padding: 18px 13px;
          margin-bottom: 16px;
        }

        .features {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 16px;

          svg {
            margin-inline-end: 12px;
            fill: #bdc6ce;
            width: 16px;
            height: 16px;
          }

          p {
            margin: 0;
            color: #484848;
          }
        }

        .clearFilter {
          width: 100%;
          background-color: #00927b;
          color: #fff;
          padding: 14px 0;
          text-align: center;
          border: none;
          border-radius: 5px;
          font-size: 16px;
        }
      }

      //   rightside sports block
      .sports {
        height: 435px;
        overflow-y: scroll;
        padding-inline-end: 10px;
        margin-bottom: 22px;
        .sportCard {
          cursor: pointer;
          border-radius: 10px;
          border: 1px solid #ecedf2;
          background: #fff;
          padding: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          #{$rtl} & {
            flex-direction: row;

            .cardImg {
              border-radius: 10px;
              background: #ecedf2;
              padding: 10px;
              margin-inline-end: 20px;

              img {
                width: 25px;
                height: 25px;
              }
            }

            p {
              margin: 0;
              font-weight: 500;
            }
          }
          #{$ltr} & {
            flex-direction: row-reverse;

            .cardImg {
              border-radius: 10px;
              background: #ecedf2;
              padding: 10px;
              margin-inline-start: 20px;

              img {
                width: 25px;
                height: 25px;
              }
            }

            p {
              margin: 0;
              font-weight: 500;
              direction: ltr;
            }
          }

          &:not(:last-of-type) {
            margin-bottom: 5px;
          }
        }
      }

      .gatherings {
        height: 363px;
        padding: 33px 30px;
        border-radius: 8px;
        border: 1px solid #ebebeb;
        background: #fff;
        margin-bottom: 22px;

        #{$rtl} & {
          direction: rtl;
        }
        #{$ltr} & {
          direction: ltr;
        }

        .headingText {
          color: #484848;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 23px;
        }

        .gathering-slideBody {
          #{$rtl} & {
            direction: rtl;
          }
          #{$ltr} & {
            direction: ltr;
          }

          .location {
            color: #00927b;
            font-weight: 700;
            margin-bottom: 23px;
            font-size: 18px;
          }
          .range {
            .from,
            .to {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;

              p {
                margin-bottom: 0;

                &.circle {
                  border-radius: 50px;
                  border: 1px solid #00927b;
                  background: #d9d9d9;
                  padding: 5px;
                  font-size: 10px;
                  font-weight: 700;
                  margin-inline-end: 14px;
                }
                &.date {
                  color: #00927b;
                  font-size: 18px;
                  font-weight: 700;
                }
              }
            }
            .from {
              position: relative;
              margin-bottom: 90px;
              &::after {
                position: absolute;
                top: 35px;

                content: "";
                height: 73px;
                border-right: 1px dashed #00927b;

                #{$rtl} & {
                  right: 13px;
                }
                #{$ltr} & {
                  left: 13px;
                }
              }
            }
            .to {
              margin-bottom: 23px;
            }
          }
        }

        .slick-arrow,
        .slick-next:before,
        .slick-prev:before {
          display: none !important;
        }

        .slick-dots {
          bottom: -30px;
          li {
            margin: 0 3px;

            button::before {
              color: #d9d9d9;
              font-size: 9px;
              opacity: 1;
            }

            &.slick-active button::before {
              color: #00927b;
              font-size: 15px;
            }
          }
        }
      }
      .participants {
        border-radius: 8px;
        border: 1px solid #ebebeb;
        background: #fff;
        padding: 15px 10px;

        #{$rtl} & {
          direction: rtl;
        }
        #{$ltr} & {
          direction: ltr;
        }

        

        .title {
          color: #313131;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 0;
        }
        hr {
          margin: 15px 0;
        }
        .participant-list {
          padding: 5px;
          height: 420px;
          overflow-y: scroll;
          .participant-card {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 5px;
            border: 1px solid rgba(6, 96, 88, 0.2);
            margin-bottom: 10px;
            height: 70px;

            img {
              margin-inline-end: 10px;
              width: 50px;
              height: 50px;
              object-fit: contain;
            }
            p {
              margin-bottom: 0;
              font-size: 11px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .leftSide {
      padding: 81px 10px 10px 10px;

      .seasonHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;

        #{$rtl} & {
          flex-direction: row;

          .heading__rightSide {
            display: flex;
            flex-direction: row;

            h1 {
              color: #00927b;
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
              margin-bottom: 0;
              margin-inline-end: 15px;
            }

            p {
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 0;
              display: flex;
              flex-direction: row;
              align-items: center;

              &:first-of-type {
                margin-inline-end: 15px;
              }

              span {
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: #00927b;
                border-radius: 50%;
                margin-inline-end: 10px;
              }
            }
          }
          .heading__leftSide {
            p {
              margin-bottom: 0;
            }
          }
        }
        #{$ltr} & {
          flex-direction: row-reverse;

          .heading__rightSide {
            display: flex;
            flex-direction: row-reverse;
            h1 {
              color: #00927b;
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
              margin-bottom: 0;
              margin-inline-end: 15px;
              direction: ltr;
            }

            p {
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 0;
              display: flex;
              flex-direction: row-reverse;
              align-items: center;

              &:first-of-type {
                margin-inline-start: 15px;
              }

              span {
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: #00927b;
                border-radius: 50%;
                margin-inline-start: 10px;
              }
            }
          }
          .heading__leftSide {
            p {
              margin-bottom: 0;
            }
          }
        }
      }

      .mediaTab {
        border-radius: 4px;
        border: 1px solid #ecedf2;
        background: #fff;
        padding: 10px 17px;
        margin: 17px 0;
        display: flex;

        justify-content: flex-start;
        align-items: center;

        #{$rtl} & {
          flex-direction: row;
        }
        #{$ltr} & {
          flex-direction: row-reverse;
        }

        .pill {
          display: flex;
          align-items: center;
          border-radius: 40px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          padding: 10px 16px;
          cursor: pointer;

          #{$rtl} & {
            flex-direction: row;

            .imgBox {
              display: none;
              margin-inline-end: 10px;
            }

            &:not(:last-of-type) {
              margin-inline-end: 20px;
            }

            &.active {
              background-color: #00927b;
              padding: 5px 5px 5px 16px;

              .imgBox {
                display: initial;
                background-color: #fff;
                border-radius: 40px;
                padding: 5px;
              }
              p {
                color: #fff;
              }
            }
          }
          #{$ltr} & {
            flex-direction: row-reverse;

            .imgBox {
              display: none;
              margin-inline-start: 10px;
            }

            &:not(:last-of-type) {
              margin-inline-start: 20px;
            }

            &.active {
              background-color: #00927b;
              padding: 5px 16px 5px 5px;

              .imgBox {
                display: initial;
                background-color: #fff;
                border-radius: 40px;
                padding: 5px;
              }
              p {
                color: #fff;
              }
            }
          }

          p {
            margin-bottom: 0;
            color: #aaaaaa;
          }
        }
      }
      .mediaContent {
        height: 500px;
        overflow-y: scroll;
        border-radius: 4px;
        border: 1px solid #ecedf2;
        background: #fff;
        padding: 40px;
        margin: 17px 0;

        .mediaCol {
          margin-bottom: 25px;
          img {
            width: 100%;
            border-radius: 15px;
          }
        }
      }

      .matchesAndResults {
        padding: 10px 0;
        display: flex;

        justify-content: space-between;
        align-items: flex-start;

        #{$rtl} & {
          flex-direction: row;

          h2 {
            font-size: 20px;
            font-weight: 500;
            line-height: 16px;
          }

          hr {
            width: 159px;
            border-top: 4px solid #00927b;
            opacity: 1;
            margin: 16px 0;
          }
        }
        #{$ltr} & {
          flex-direction: row-reverse;

          h2 {
            font-size: 20px;
            font-weight: 500;
            line-height: 16px;
            direction: ltr;
          }

          hr {
            width: 159px;
            border-top: 4px solid #00927b;
            opacity: 1;
            margin: 16px 0;
            margin-inline-start: auto;
          }
        }

        .content {
          width: 100%;
          border-radius: 15px;
          border: 1px solid #ecedf2;
          background: #fff;
          padding: 10px;
          position: relative;
        }

        .nextMatch__container {
          width: 40%;

          .content {
            .remainingTime {
              position: absolute;
              background: #f8c83b;
              padding: 10px 25px;
              border-radius: 0px 0px 15px 15px;
              top: 0;
              left: 18%;

              p {
                margin: 0;
                font-size: 10px;
              }
            }

            .topSection {
              display: flex;
              flex-direction: row;
              margin-bottom: 10px;

              .matchSport {
                width: 50%;
                padding: 6px 0px;

                .sport {
                  width: fit-content;
                  padding: 10px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  border-radius: 15px;
                  background: #eee;

                  img {
                    width: 20px;
                    height: 20px;
                    margin-inline-end: 10px;
                  }

                  p {
                    margin: 0;
                    color: #00927b;
                    font-size: 12px;
                    font-weight: 500;
                  }
                }
              }

              .matchDate {
                width: 50%;

                padding: 31px 10px 0px 10px;
                display: flex;
                align-items: flex-end;

                p {
                  margin: 0;
                  font-size: 14px;
                }
              }
            }

            .sides {
              border-radius: 15px;
              background: #f2f3f4;
              padding: 10px;

              .side {
                padding: 11px 12px;
                border-radius: 15px;
                background: #fff;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .sideImg {
                  border-radius: 50%;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 20px 0 10px;

                  &.greenBorder {
                    border: 2px solid #00927b;
                  }

                  &.yellowBorder {
                    border: 2px solid #efc420;
                  }
                }

                p {
                  margin: 0;
                  padding: 10px;
                  font-size: 12px;
                }

                &:first-of-type {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }

        .newResults__container {
          width: 55%;

          .content {
            padding: 0;

            .emptyData {
              img {
                width: 20%;
              }
            }

            .slideBody {
              position: relative;
              padding: 10px 10px 40px 10px;
              #{$rtl} & {
                direction: rtl;
              }
              #{$ltr} & {
                direction: ltr;
              }

              .tag {
                position: absolute;
                border-radius: 0px 0px 15px 15px;
                background: #e72678;
                padding: 10px 25px;
                top: 0;
                left: 10%;

                p {
                  margin: 0;
                  font-size: 10px;
                  color: #fff;
                }
              }

              .topSection {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 10px;

                .matchSport {
                  width: fit-content;
                  padding: 6px 0px;

                  .sport {
                    width: fit-content;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-radius: 15px;
                    background: #eee;

                    img {
                      width: 20px;
                      height: 20px;
                      margin-inline-end: 10px;
                    }

                    p {
                      margin: 0;
                      color: #00927b;
                      font-size: 12px;
                      font-weight: 500;
                    }
                  }
                }

                .matchDate {
                  #{$rtl} & {
                    padding: 15px 37px 15px 0px;
                  }
                  #{$ltr} & {
                    padding: 15px 0 15px 37px;
                  }
                  display: flex;
                  align-items: center;

                  p {
                    margin: 0;
                    font-size: 14px;
                  }
                }
              }

              .score {
                .sides {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  border-radius: 15px;
                  padding: 10px;
                  background: #f2f3f4;

                  .side {
                    flex: 1;
                    padding: 11px 12px;
                    border-radius: 15px;
                    background-color: #fff;
                    display: flex;
                    flex-direction: row;

                    &.side1 {
                      .sideInfo {
                        margin-inline-end: 10px;
                      }
                    }

                    &.side2 {
                      .siideScore {
                        margin-inline-end: 10px;
                      }
                    }

                    .sideInfo {
                      flex: 3;
                      display: flex;
                      flex-direction: column;
                      align-items: center;

                      .sideImg {
                        border-radius: 50%;
                        width: 52px;
                        height: 51px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 10px;

                        &.greenBorder {
                          border: 2px solid #00927b;
                        }

                        &.yellowBorder {
                          border: 2px solid #efc420;
                        }
                      }

                      p {
                        text-align: center;
                        font-size: 12px;
                        padding: 10px;
                        margin: 0;
                      }
                    }

                    .sideScore {
                      width: 50px;
                      border-radius: 15px;
                      background: #f2f3f4;
                      display: flex;
                      justify-content: center;
                      align-items: center;

                      p {
                        margin: 0;
                        font-size: 30px;
                      }
                    }

                    &:first-of-type {
                      margin-inline-end: 10px;
                    }
                  }
                }
              }
            }
          }

          .slick-arrow,
          .slick-next:before,
          .slick-prev:before {
            display: none !important;
          }

          .slick-dots {
            bottom: 8px;

            li {
              margin: 0 3px;

              button::before {
                color: #d9d9d9;
                font-size: 9px;
                opacity: 1;
              }

              &.slick-active button::before {
                color: #00927b;
                font-size: 15px;
              }
            }
          }
        }
      }
      .selectedSport {
        text-align: center;
      }

      .teamsListing {
        h1 {
          margin: 18px 0 0;
          padding: 25px 21px;
          font-size: 36px;
          color: #000;
          text-align: center;
          &.bold {
            font-weight: 500;
          }
          &.bolder {
            font-weight: 700;
          }
        }

        .accordion {
          max-height: 400px;
          overflow-y: scroll;
        }

        .accordion-button {
          &:not(.collapsed) {
            background-color: #e6e6e6;

            p {
              color: #000;
            }
          }

          &::after {
            #{$rtl} & {
              margin-right: auto;
              margin-left: 0;
            }
            #{$ltr} & {
              margin-left: auto;
              margin-right: 0;
            }
          }
        }

        .accordHeading {
          display: flex;
          align-items: center;
          padding: 10px;

          #{$rtl} & {
            flex-direction: row;
            img {
              width: 20px;
              height: 20px;
              margin-inline-end: 10px;
            }
          }

          #{$ltr} & {
            flex-direction: row-reverse;

            img {
              width: 20px;
              height: 20px;
              margin-inline-start: 10px;
            }
          }

          P {
            margin: 0;
          }
        }

        .accordion-body {
          overflow-x: scroll;
        }

        .table {
          #{$rtl} & {
            direction: rtl;
          }
          #{$ltr} & {
            direction: ltr;
          }
        }

        tr {
          th {
            background-color: #fafafa;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            line-height: 3;
          }
          td {
            text-align: center;
            line-height: 3;

            img {
              width: 49px;
              height: 49px;
              object-fit: contain;
            }

            &.uniName {
              font-weight: 700;
            }
          }
        }
      }

      .matches {
        h1 {
          margin: 18px 0 0;
          padding: 25px 21px;
          font-size: 36px;
          font-weight: 500;
          color: #000;
          text-align: center;
        }

        .matchHolder {
          margin-bottom: 20px;

          .content {
            width: 100%;
            border-radius: 15px;
            border: 1px solid #ecedf2;
            background: #fff;
            padding: 10px;
            position: relative;

            .matchStatus {
              position: absolute;
              padding: 10px 25px;
              border-radius: 0px 0px 15px 15px;
              top: 0;

              #{$rtl} & {
                left: 8%;
              }
              #{$ltr} & {
                right: 8%;
              }

              &.live {
                background-color: #e72678;
                color: #fff;
              }

              &.upcoming {
                background-color: #eaf9ef;
                color: #3aab98;
              }

              &.finished {
                background-color: #f2f3f4;
              }

              p {
                margin: 0;
                font-size: 10px;
              }
            }

            .topSection {
              display: flex;
              margin-bottom: 10px;

              #{$rtl} & {
                flex-direction: row;
              }
              #{$ltr} & {
                flex-direction: row-reverse;
              }

              .matchSport {
                flex: 1;
                padding: 6px 0px;

                #{$rtl} & {
                  direction: rtl;
                }
                #{$ltr} & {
                  direction: ltr;
                }

                .sport {
                  width: fit-content;
                  padding: 10px;
                  display: flex;
                  align-items: center;
                  border-radius: 15px;
                  background: #eee;

                  #{$rtl} & {
                    flex-direction: row;
                    img {
                      width: 20px;
                      height: 20px;
                      margin-inline-end: 10px;
                    }
                  }
                  #{$ltr} & {
                    flex-direction: row-reverse;
                    img {
                      width: 20px;
                      height: 20px;
                      margin-inline-start: 10px;
                    }
                  }

                  p {
                    margin: 0;
                    color: #00927b;
                    font-size: 12px;
                    font-weight: 500;
                  }
                }
              }

              .remainingTime {
                flex: 2;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                #{$rtl} & {
                  direction: rtl;
                }
                #{$ltr} & {
                  direction: ltr;
                }

                p {
                  margin: 0;
                  font-size: 14px;

                  &:first-of-type {
                    margin-inline-end: 5rem;
                  }
                }
              }
            }

            .sides {
              border-radius: 15px;
              background: #f2f3f4;
              padding: 10px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .side {
                flex: 1;
                border-radius: 15px;
                background: #fff;
                padding: 11px 12px;
                display: flex;
                flex-direction: row;

                .sideInfo {
                  flex: 3;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .sideImg {
                    border-radius: 50%;
                    width: 52px;
                    height: 51px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;

                    &.greenBorder {
                      border: 2px solid #00927b;
                    }

                    &.yellowBorder {
                      border: 2px solid #efc420;
                    }
                  }

                  p {
                    text-align: center;
                    margin: 0;
                    font-size: 12px;
                    padding: 10px;
                  }
                }

                .sideScore {
                  flex: 1;
                  border-radius: 15px;
                  background: #f2f3f4;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  p {
                    margin: 0;
                    font-size: 30px;
                  }
                }

                &.side1 {
                  .sideInfo {
                    margin-inline-end: 10px;
                    flex-direction: row-reverse;
                  }
                }

                &.side2 {
                  .sideInfo {
                    flex-direction: row;
                  }

                  .siideScore {
                    margin-inline-end: 10px;
                  }
                }

                &:first-of-type {
                  margin-inline-end: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1440.5px) {
    .main {
      .rightSide {
        .filters {
          padding: 31px 20px;
        }
      }

      .leftSide {
        .matchesAndResults {
          .nextMatch__container {
            .content {
              .remainingTime {
                left: 6%;
              }
            }
          }

          .newResults__container {
            .content {
              .tag {
                left: 8%;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024.5px) {
    .main {
      padding: 40px;

      .rightSide {
        padding-top: 40px;
      }

      .leftSide {
        padding-top: 40px;

        .matchesAndResults {
          flex-direction: column;

          .nextMatch__container {
            width: 100%;
            margin-bottom: 2rem;

            .content {
              .topSection {
                .matchSport {
                  width: fit-content;
                  margin-inline-end: 1rem;
                }

                .matchDate {
                  width: fit-content;
                  padding: 10px;
                  align-items: center;
                }
              }
            }
          }

          .newResults__container {
            width: 100%;

            .content {
              .topSection {
                .matchSport {
                  width: fit-content;
                }
              }
            }
          }
        }

        .matches {
          .matchHolder {
            .content {
              .matchStatus {
                left: 5%;
                padding: 5px 20px 3px;
              }

              .topSection {
                .matchSport {
                  flex: inherit;
                  margin-inline-end: 1rem;
                }

                .remainingTime {
                  flex: inherit;

                  p {
                    font-size: 12px;

                    &:first-of-type {
                      // margin-inline-end: 1rem;
                    }
                  }
                }
              }

              .sides {
                .side {
                  .sideInfo {
                    p {
                      font-size: 10px;
                      padding: 10px 5px;
                    }
                  }

                  .sideScore {
                    flex: initial;
                    padding: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768.5px) {
    .main {
      .rightSide {
        .filters {
          .dropdown {
            .btn {
              .label {
                p {
                  font-size: 12px;
                }
              }
            }
          }

          .datePicker {
            font-size: 12px;
          }

          .features {
            font-size: 12px;
          }
        }
      }

      .leftSide {
        .matchesAndResults {
          .nextMatch__container {
            .content {
              .remainingTime {
                padding: 5px 20px 3px;
              }

              .topSection {
                .matchSport {
                  margin-inline-end: 4.5rem;
                }

                .matchDate {
                  p {
                    font-size: 12px;
                  }
                }
              }
            }
          }

          .newResults__container {
            .content {
              .slideBody {
                .tag {
                  left: 5%;
                  padding: 5px 20px;
                }

                .topSection {
                  .matchSport {
                    width: fit-content;
                  }

                  .matchDate {
                    padding: 0 20px 0 0;

                    p {
                      font-size: 12px;
                    }
                  }
                }

                .score {
                  .sides {
                    flex-direction: column;

                    .side {
                      &.side1 {
                        .sideInfo {
                          margin-inline-end: 0;
                        }
                      }

                      &.side2 {
                        .siideScore {
                          margin-inline-end: 0;
                        }
                      }

                      &:first-of-type {
                        margin-inline-end: 0;
                        margin-bottom: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .teamsListing {
          .accordion-body {
            padding: 10px;

            tr {
              th,
              td {
                font-size: 11px;
                line-height: 1.3;
              }
            }
          }
        }

        .matches {
          .matchHolder {
            .content {
              .topSection {
                .matchSport {
                  flex: initial;
                  width: fit-content;
                  margin-inline-end: 10px;
                }

                .remainingTime {
                  flex: initial;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: center;

                  p {
                    font-size: 11px;

                    &:first-of-type {
                      margin-inline-end: 0;
                    }
                  }
                }
              }

              .sides {
                flex-direction: column;

                .side {
                  &.side1 {
                    .sideInfo {
                      margin-inline-end: 0;
                    }
                  }

                  &.side2 {
                    .siideScore {
                      margin-inline-end: 0;
                    }
                  }

                  &:first-of-type {
                    margin-inline-end: 0;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 575.5px) {
    margin-bottom: 0;

    .main {
      padding: 10px 20px;

      .rightSide {
        padding: 20px 10px 0;
        .sports {
          display: none;
        }
      }

      .leftSide {
        .seasonHeading {
          flex-direction: column;
        }
        .matchesAndResults{
          flex-direction: column !important;
        }
        .matches {
          .matchHolder {
            .content {
              .topSection {
                .remainingTime {
                  p {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 425.5px) {
    .main {
      .leftSide {
        .matches {
          .matchHolder {
            .content {
              .topSection {
                .remainingTime {
                  p {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.grid {
  -webkit-column-count: 4;
  column-count: 4;
  grid-column-gap: 0.5em;
  -webkit-column-gap: 0.5em;
  column-gap: 0.5em;
  max-width: 980px;
  margin: auto;
  width: 100%;

  img {
    height: auto;
    max-width: 100%;
    margin-bottom: 10px;
    cursor: zoom-in;
  }
}

.media-box {
  margin: 15px 0;
  position: relative;
  height: 220px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #707070;
  overflow: hidden;

  img.main-img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    transition: all 0.5s ease-in-out;
  }

  .play-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 70px;
    z-index: 99;
    width: 100px;
    opacity: 0.5;
  }
}

.displayNone {
  display: none;
}
