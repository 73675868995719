@import "../../../scss/vars.scss";

.matchesAndResults {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 16px;
  }

  hr {
    width: 159px;
    border-top: 4px solid #00927b;
    opacity: 1;
    margin: 16px 0;
  }

  .content {
    width: 100%;
    border-radius: 15px;
    border: 1px solid #ecedf2;
    background: #fff;
    padding: 10px;
    position: relative;
  }

  .nextMatch__container {
    width: 40%;

    .content {
      .remainingTime {
        position: absolute;
        background: #f8c83b;
        padding: 10px 25px;
        border-radius: 0px 0px 15px 15px;
        top: 0;

        #{$rtl} & {
          left: 18%;
        }
        #{$ltr} & {
          right: 18%;
        }

        p {
          margin: 0;
          font-size: 10px;
        }
      }

      .topSection {
        display: flex;

        margin-bottom: 10px;

        #{$rtl} & {
          flex-direction: row;
        }
        #{$ltr} & {
          flex-direction: row-reverse;
        }

        .matchSport {
          width: 50%;
          padding: 6px 0px;

          .sport {
            width: fit-content;
            padding: 10px;
            display: flex;
            align-items: center;
            border-radius: 15px;
            background: #eee;

            #{$rtl} & {
              flex-direction: row;
              img {
                width: 20px;
                height: 20px;
                margin-inline-end: 10px;
              }
            }
            #{$ltr} & {
              flex-direction: row-reverse;
              img {
                width: 20px;
                height: 20px;
                margin-inline-start: 10px;
              }
            }

            p {
              margin: 0;
              color: #00927b;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }

        .matchDate {
          width: 50%;

          padding: 31px 10px 0px 10px;
          display: flex;
          align-items: flex-end;

          p {
            margin: 0;
            font-size: 14px;
          }
        }
      }

      .sides {
        border-radius: 15px;
        background: #f2f3f4;
        padding: 10px;

        .side {
          padding: 11px 12px;
          border-radius: 15px;
          background: #fff;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          #{$rtl} & {
            flex-direction: row;
          }
          #{$ltr} & {
            flex-direction: row-reverse;
          }

          .sideImg {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            #{$rtl} & {
              margin: 0 20px 0 10px;
            }
            #{$ltr} & {
              margin: 0 10px 0 20px;
            }

            img {
              width: 30px;
            }

            &.greenBorder {
              border: 2px solid #00927b;
            }

            &.yellowBorder {
              border: 2px solid #efc420;
            }
          }

          p {
            margin: 0;
            padding: 10px;
            font-size: 12px;
          }

          &:first-of-type {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .newResults__container {
    width: 55%;

    .content {
      padding: 0;

      .emptyData {
        img {
          width: 20%;
        }
      }

      .slideBody {
        position: relative;
        padding: 10px 10px 40px 10px;

        #{$rtl} & {
          direction: rtl;

          .tag {
            position: absolute;
            border-radius: 0px 0px 15px 15px;
            background: #e72678;
            padding: 10px 25px;
            top: 0;
            left: 10%;

            p {
              margin: 0;
              font-size: 10px;
              color: #fff;
            }
          }
        }
        #{$ltr} & {
          direction: ltr;

          .tag {
            position: absolute;
            border-radius: 0px 0px 15px 15px;
            background: #e72678;
            padding: 10px 25px;
            top: 0;
            right: 10%;

            p {
              margin: 0;
              font-size: 10px;
              color: #fff;
            }
          }
        }

        .topSection {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;

          .matchSport {
            padding: 6px 0px;
            margin-inline-end: 20px;

            .sport {
              width: fit-content;
              padding: 10px;
              display: flex;
              flex-direction: row;
              align-items: center;
              border-radius: 15px;
              background: #eee;

              img {
                width: 20px;
                height: 20px;
                margin-inline-end: 10px;
              }

              p {
                margin: 0;
                color: #00927b;
                font-size: 12px;
                font-weight: 500;
              }
            }
          }

          .matchPath {
            text-decoration: none;

            div {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #efc420;
              border-radius: 15px;
              padding: 10px;

              img {
                margin-inline-end: 10px;
                width: 20px;
                height: 20px;
              }

              p {
                margin-bottom: 0;
                font-size: 12px;
                color: #000;
              }
            }
          }

          .matchDate {
            #{$rtl} & {
              padding: 15px 37px 15px 0px;
            }
            #{$ltr} & {
              padding: 15px 0 15px 37px;
            }

            display: flex;
            align-items: center;

            p {
              margin: 0;
              font-size: 14px;
            }
          }
        }

        .score {
          .sides {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-radius: 15px;
            padding: 10px;
            background: #f2f3f4;

            .side {
              flex: 1;
              padding: 11px 12px;
              border-radius: 15px;
              background-color: #fff;
              display: flex;
              flex-direction: row;

              &.side1 {
                .sideInfo {
                  margin-inline-end: 10px;
                }
              }

              &.side2 {
                .siideScore {
                  margin-inline-end: 10px;
                }
              }

              .sideInfo {
                flex: 3;
                display: flex;
                flex-direction: column;
                align-items: center;

                .sideImg {
                  border-radius: 50%;
                  width: 52px;
                  height: 51px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-bottom: 10px;

                  &.greenBorder {
                    border: 2px solid #00927b;
                  }

                  &.yellowBorder {
                    border: 2px solid #efc420;
                  }
                }

                p {
                  text-align: center;
                  font-size: 12px;
                  padding: 10px;
                  margin: 0;
                }
              }

              .sideScore {
                width: 50px;
                border-radius: 15px;
                background: #f2f3f4;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                  margin: 0;
                  font-size: 30px;
                }
              }

              &:first-of-type {
                margin-inline-end: 10px;
              }
            }
          }
        }
      }
    }

    .slick-arrow,
    .slick-next:before,
    .slick-prev:before {
      display: none !important;
    }

    .slick-dots {
      bottom: 8px;

      li {
        margin: 0 3px;

        button::before {
          color: #d9d9d9;
          font-size: 9px;
          opacity: 1;
        }

        &.slick-active button::before {
          color: #00927b;
          font-size: 15px;
        }
      }
    }
  }
}

.selectedSport {
  text-align: center;
}

.teamsListing {
  h1 {
    margin: 18px 0 0;
    padding: 25px 21px;
    font-size: 36px;
    color: #000;
    text-align: center;

    &.bold {
      font-weight: 500;
    }

    &.bolder {
      font-weight: 700;
    }
  }

  .accordion {
    max-height: 400px;
    overflow-y: scroll;
    padding-inline-end: 10px;
  }

  .accordion-button {
    &:not(.collapsed) {
      background-color: #e6e6e6;

      p {
        color: #000;
      }
    }

    &::after {
      #{$rtl} & {
        margin-right: auto;
        margin-left: 0;
      }
      #{$ltr} & {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  .accordHeading {
    display: flex;
    align-items: center;
    padding: 10px;

    #{$rtl} & {
      flex-direction: row;
      img {
        width: 20px;
        height: 20px;
        margin-inline-end: 10px;
      }
    }

    #{$ltr} & {
      flex-direction: row-reverse;

      img {
        width: 20px;
        height: 20px;
        margin-inline-start: 10px;
      }
    }

    P {
      margin: 0;
    }
  }

  .accordion-body {
    overflow-x: scroll;
  }

  .table {
    #{$rtl} & {
      direction: rtl;
    }
    #{$ltr} & {
      direction: ltr;
    }
  }

  tr {
    th {
      background-color: #fafafa;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      line-height: 3;
    }

    td {
      text-align: center;
      line-height: 3;

      img {
        width: 49px;
        height: 49px;
        object-fit: contain;
      }

      &.uniName {
        font-weight: 700;
      }
    }
  }
}

.matches {
  .matchesList {
    max-height: 625px;
    overflow-y: scroll;
    padding-inline-end: 10px;
  }

  h1 {
    margin: 18px 0 0;
    padding: 25px 21px;
    font-size: 36px;
    font-weight: 500;
    color: #000;
    text-align: center;
  }

  .matchHolder {
    margin-bottom: 20px;

    .content {
      width: 100%;
      border-radius: 15px;
      border: 1px solid #ecedf2;
      background: #fff;
      padding: 10px;
      position: relative;

      .matchStatus {
        position: absolute;
        padding: 10px 25px;
        border-radius: 0px 0px 15px 15px;
        top: 0;
        #{$rtl} & {
          left: 8%;
        }
        #{$ltr} & {
          right: 8%;
        }

        &.live {
          background-color: #e72678;
          color: #fff;
        }

        &.upcoming {
          background-color: #eaf9ef;
          color: #3aab98;
        }

        &.finished {
          background-color: #f2f3f4;
        }

        p {
          margin: 0;
          font-size: 10px;
        }
      }

      .topSection {
        display: flex;
        margin-bottom: 10px;

        #{$rtl} & {
          flex-direction: row;
        }
        #{$ltr} & {
          flex-direction: row-reverse;
        }

        .matchSport {
          flex: 1;
          padding: 6px 0px;

          #{$rtl} & {
            direction: rtl;
          }
          #{$ltr} & {
            direction: ltr;
          }

          .sport {
            width: fit-content;
            padding: 10px;
            display: flex;
            align-items: center;
            border-radius: 15px;
            background: #eee;

            #{$rtl} & {
              flex-direction: row;
              img {
                width: 20px;
                height: 20px;
                margin-inline-end: 10px;
              }
            }
            #{$ltr} & {
              flex-direction: row-reverse;
              img {
                width: 20px;
                height: 20px;
                margin-inline-start: 10px;
              }
            }

            p {
              margin: 0;
              color: #00927b;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }

        .remainingTime {
          flex: 2;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          #{$rtl} & {
            direction: rtl;
          }
          #{$ltr} & {
            direction: ltr;
          }

          p {
            margin: 0;
            font-size: 14px;

            &:first-of-type {
              margin-inline-end: 5rem;
            }
          }
        }
      }

      .sides {
        border-radius: 15px;
        background: #f2f3f4;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .side {
          flex: 1;
          border-radius: 15px;
          background: #fff;
          padding: 11px 12px;
          display: flex;
          flex-direction: row;

          .sideInfo {
            flex: 3;
            display: flex;
            justify-content: center;
            align-items: center;

            .sideImg {
              border-radius: 50%;
              width: 52px;
              height: 51px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 10px;

              img {
                width: 30px;
              }

              &.greenBorder {
                border: 2px solid #00927b;
              }

              &.yellowBorder {
                border: 2px solid #efc420;
              }
            }

            p {
              text-align: center;
              margin: 0;
              font-size: 12px;
              padding: 10px;
            }
          }

          .sideScore {
            flex: 1;
            border-radius: 15px;
            background: #f2f3f4;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              margin: 0;
              font-size: 30px;
            }
          }

          &.side1 {
            .sideInfo {
              margin-inline-end: 10px;
              flex-direction: row-reverse;
            }
          }

          &.side2 {
            .sideInfo {
              flex-direction: row;
            }

            .siideScore {
              margin-inline-end: 10px;
            }
          }

          &:first-of-type {
            margin-inline-end: 10px;
          }
        }
      }
    }
  }
}

.filterRow {
  background-color: white;
  padding: 10px;
  margin-bottom: 11px;
  border: 1px solid #e6e8eb;
  border-radius: 5px;

  #{$rtl} & {
    flex-direction: row;
  }
  #{$ltr} & {
    flex-direction: row-reverse;
  }

  .datePicker {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    background: #fff;
    padding: 10px 13px;
    // margin-bottom: 16px;
  }

  .clearFilter {
    width: 100%;
    background-color: #00927b;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    border: none;
    border-radius: 5px;
    font-size: 16px;
  }
}

.matchPath2 {
  color: unset;
  text-decoration: unset;
}

@media screen and (max-width: 575.5px) {
  .marginb5 {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 425.5px) {
  .marginb5 {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 768.5px) {
  .marginb5 {
    margin-bottom: 5px;
  }
}
