@import "../../../scss/vars.scss";

.headingImg {
  position: relative;
  height: 220px;
  overflow: hidden;
  background-image: url("../../../Assets/MediaCenter/Activities/headingImg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 35%;
  z-index: 1;

  .headingIcon {
    position: absolute;
    left: 20%;
    top: 0;
    z-index: 4;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: rgba(228, 236, 249, 0.5);
  }
}

.news-list {
  padding: 50px 20px 4rem;

  .cards-row {
    #{$rtl} & {
      flex-direction: row;
      .news-card {
        margin-bottom: 40px;
        text-align: center;
        .card-img {
          width: 370px;
          height: 260px;
          margin: auto;
          img {
            border-radius: 30px;
            width: 100%;
          }
        }
        .card-body {
          width: 80%;
          height: 200px;
          margin: auto;
          padding: 1.5rem 3rem 1rem;
          border-radius: 30px;
          background: #a0d0c6;
          transform: translateY(-65px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &__top {
            width: 100%;
            text-align: start;
          }
          &__botton {
            width: 100%;
          }

          p {
            margin: 0;
            direction: rtl;
            &.news-date {
              color: #00927b;
              font-weight: 700;
              margin-bottom: 10px;
              span {
                margin-inline-end: 10px;
              }
            }
            &.news-text {
              text-align: start;
              font-size: 14px;
              font-weight: 700;
            }
            &.news-location {
              width: 100%;
              color: #00927b;
              text-align: end;
            }
          }
          hr {
            width: 100%;
            border-top: 2px solid #dedede;
            opacity: 1;
            margin: 0.5rem 0;
          }

          a {
            text-decoration: none;
            color: #212529;
          }
        }

        @media screen and (max-width: 1440px) {
          .card-img {
            width: initial;
          }
          .card-body {
            width: initial;
          }
        }
      }
    }

    #{$ltr} & {
      flex-direction: row-reverse;

      .news-card {
        margin-bottom: 40px;
        text-align: center;
        .card-img {
          width: 370px;
          height: 260px;
          margin: auto;
          img {
            border-radius: 30px;
            width: 100%;
          }
        }
        .card-body {
          width: 80%;
          height: 200px;
          margin: auto;
          padding: 1.5rem 3rem 1rem;
          border-radius: 30px;
          background: #a0d0c6;
          transform: translateY(-65px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &__top {
            width: 100%;
            text-align: start;
          }
          &__botton {
            width: 100%;
          }

          p {
            direction: ltr;
            margin: 0;
            &.news-date {
              color: #00927b;
              font-weight: 700;
              margin-bottom: 10px;
              span {
                margin-inline-end: 10px;
              }
            }
            &.news-text {
              text-align: start;
              font-size: 14px;
              font-weight: 700;
            }
            &.news-location {
              width: 100%;
              color: #00927b;
              text-align: end;
            }
          }
          hr {
            width: 100%;
            border-top: 2px solid #dedede;
            opacity: 1;
            margin: 0.5rem 0;
          }

          a {
            text-decoration: none;
            color: #212529;
          }
        }

        @media screen and (max-width: 1440px) {
          .card-img {
            width: initial;
          }
          .card-body {
            width: initial;
          }
        }
      }
    }
  }
}
