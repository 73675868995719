.technical-support {
    .content {
        background-color: #f7f7f5;
        padding: 60px 50px;
        text-align: right;

        .form {
            background-color: #fff;
            border: 1px solid #eee;
            border-radius: 12px;
            box-shadow: 0px 0px 8px 0 #d4d2d2;
            padding: 40px 25px;
            position: relative;
            margin-bottom: 40px;

            img {
                position: absolute;
                left: 25px;
                top: -40px;
                width: 100px;
                box-shadow: 0px 0px 8px 0 #adb5bd;
                background: #fff;
                padding: 10px;
                border-radius: 10px;
            }

            h3 {
                color: #009f7c;
                font-family: "Alexandria", sans-serif !important;
                font-size: 18px;
                margin-bottom: 20px;
            }

            .fields {
                .form-group {
                    margin-bottom: 10px;
                }

                label {
                    font-size: 14px;
                    margin-bottom: 2px;
                    color: #707070;
                }

                textarea {
                    height: 120px;
                    resize: none;
                }

                button {
                    background-color: #009f7c;
                    border: 1px solid #009f7c;
                    padding: 15px 25px;
                    min-width: 200px;
                    color: #fff;
                    display: inline-block;
                    border-radius: 10px;
                    text-align: center;
                    transition: all 0.3s ease-in-out;
                    margin-top: 20px;

                    &:hover {
                        background-color: #fff;
                        color: #009f7c;
                    }
                }
            }
        }
    }
}

.alertmsg {
    width: 100%;
    border: 1px solid #ebe9e9;
    padding: 10px;
    background-color: #f7f7f5;
    color: #9f6060;
    margin-bottom: 15px;
    border-radius: 10px;
}