@import "../../../scss/vars.scss";

.headingImg {
  position: relative;
  height: 220px;
  overflow: hidden;
  background-image: url("../../../Assets/MediaCenter/Activities/headingImg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 35%;
  z-index: 1;

  .headingIcon {
    position: absolute;
    left: 20%;
    top: 0;
    z-index: 4;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: rgba(228, 236, 249, 0.5);
  }
}

.mediaList {
  padding: 60px 20px 6rem;

  .media-row {
    #{$rtl} & {
      flex-direction: row;
    }
    #{$ltr} & {
      flex-direction: row-reverse;
    }
  }

  .media-card {
    margin: 0 10px 60px;
    padding: 48px 37px;
    border-radius: 18px;
    border: 2px solid #000;
    background: #fff;
    position: relative;

    #{$rtl} & {
      direction: rtl;
    }
    #{$ltr} & {
      direction: ltr;
    }

    .card-datePill {
      position: absolute;
      border-radius: 26px;
      border: 2px solid #000;
      background: #00927b;
      box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.2);
      padding: 10px 34px;
      top: -20px;

      p {
        margin: 0;
        font-size: 10px;
        color: #fff;
        img {
          margin-inline-end: 10px;
        }
      }
    }
    .card-body {
      .card-label {
        font-size: 13px;
        font-weight: 700;
        line-height: 24px;
        height: 70px;
        margin-bottom: 36px;
      }
      .card-img {
        margin-bottom: 36px;
        height: 210px;
        box-shadow: -2px 0px 18px rgba(0, 0, 0, 0.38);
        border-radius: 18px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 18px;
        }
      }
      .cardBtn {
        border-radius: 56px;
        border: 2px solid #000;
        background: rgba(255, 255, 255, 0);
        box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.2);
        padding: 2px;
        .btn {
          width: 100%;
          border-radius: 56px;
          background-color: #00927b;
          color: #fff;
          position: relative;

          svg {
            position: absolute;
            right: 15px;
            top: 5px;
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
