@import "../../../scss/vars.scss";

.headingImg {
  position: relative;
  height: 220px;
  overflow: hidden;
  background-image: url("../../../Assets/MediaCenter/Activities/headingImg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 35%;
  z-index: 1;

  .headingIcon {
    position: absolute;
    left: 20%;
    top: 0;
    z-index: 4;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: rgba(228, 236, 249, 0.5);
  }
}

.admin-structure {
  .data {
    padding: 50px 120px;
    h3 {
      font-size: 22px;
      color: #000;
      font-weight: 500;

      #{$ltr} & {
        direction: ltr;
      }
    }

    h5 {
      font-size: 18px;
      color: #000;
      margin-bottom: 20px;

      #{$ltr} & {
        direction: ltr;
      }
    }

    ul {
      margin: 40px 0;
      padding: 0;
      #{$ltr} & {
        direction: ltr;
      }
      li {
        color: #000;
        margin-bottom: 5px;
        font-size: 15px;
      }
    }

    @media screen and (max-width: 575.5px) {
      padding: 50px 25px;
    }
  }

  .org-chart {
    width: 100%;
    margin: auto;
    padding: 10px 40px 12rem;

    .box {
      width: 160px;
      padding: 15px 10px;
      font-size: 12px;
      margin: auto;
      background-color: #eee;
      color: #000;
      font-weight: bold;
      text-align: center;
      border-radius: 8px;
      background: linear-gradient(
        to right top,
        rgba(255, 255, 255, 0.5) 6%,
        rgba(242, 241, 239, 1) 94%
      );
      border: 1px dashed;
      position: relative;

      span {
        position: absolute;
        display: block;

        &.dashes {
          left: 0;
          width: 100%;
          height: 100%;
          top: 0;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 16px;
            width: 5px;
            height: 20px;
            display: block;
            border-radius: 0 3px 3px 0;
          }

          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 16px;
            width: 5px;
            height: 20px;
            display: block;
            border-radius: 3px 0px 0px 3px;
          }
        }

        &.separator {
          left: 0;
          right: 0;
          top: -1px;
          margin: auto;
          width: 40px;
          height: 2px;
          background-color: #fff;
        }
      }
    }

    .top-part {
      margin-bottom: 70px;
      position: relative;
      .levelup {
        text-align: center;
        margin-bottom: 40px;
        position: relative;

        .box {
          border-color: #ff9b50;
          .separator {
            top: auto;
            bottom: -1px;
          }
          .dashes {
            &:before,
            &:after {
              background-color: #ff9b50;
            }
          }
        }
      }

      .levelmiddle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        padding: 0 20px;
        position: relative;
        .box {
          margin: initial;
          border-color: #7702d5;
          .dashes {
            &:before,
            &:after {
              background-color: #7702d5;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ff5e55;
          top: 46%;
          left: calc(100% - 190px);
          margin: auto;
          display: block;
        }

        &::after {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ff5e55;
          top: 46%;
          right: calc(100% - 190px);
          margin: auto;
          display: block;
        }
      }

      .leveldown {
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        .box {
          border-color: #7702d5;
          .dashes {
            &:before,
            &:after {
              background-color: #7702d5;
            }
          }
        }
        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ff5e55;
          top: -18%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }

        &::after {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ff5e55;
          top: -130%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }
      }

      .leveldown2 {
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        .box {
          border-color: #7702d5;
          .dashes {
            &:before,
            &:after {
              background-color: #7702d5;
            }
          }
          .separator {
            top: auto;
            bottom: -1px;
          }
        }
        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ff5e55;
          top: -18%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }

        // &::after {
        //   content: "";
        //   position: absolute;
        //   width: 5px;
        //   height: 5px;
        //   border-radius: 50%;
        //   background-color: #ff5e55;
        //   top: -129%;
        //   left: 0;
        //   right: 0;
        //   margin: auto;
        //   display: block;
        // }
      }

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 41%;
        background-color: #ff5e55;
        bottom: 42%;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
      }

      &::before {
        content: "";
        position: absolute;
        width: calc(100% - 380px);
        height: 1px;
        background-color: #ff5e55;
        top: 38.2%;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
      }
    }

    .low-part {
      position: relative;
      .levelup {
        margin-bottom: 45px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 75%;
          height: 1px;
          background-color: #6502c9;
          top: -46%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }
      }

      .leveldown {
        display: flex;
        justify-content: space-between;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 75%;
          height: 1px;
          background-color: #6502c9;
          top: -46%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }
      }

      .box {
        position: relative;
        border-color: #34adde;

        .dashes {
          &:before,
          &:after {
            background-color: #34adde;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 35%;
          background-color: #6502c9;
          top: -50%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }

        &::after {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #6502c9;
          top: -53%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #6502c9;
        top: -17.5%;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
      }

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 97%;
        background-color: #6502c9;
        top: -48%;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .admin-structure {
    .org-chart {
      padding: 80px 40px;
    }
  }
}

@media screen and (max-width: 768px) {
  .admin-structure {
    .org-chart {
      padding: 80px 15px 18rem;
      zoom: 0.5;
    }
  }
}

@media screen and (max-width: 425px) {
  .admin-structure {
    .org-chart {
      padding: 80px 15px;
      zoom: 0.4;
    }
  }
}
