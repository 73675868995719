@import "../../../scss/vars.scss";

.headingImg {
  position: relative;
  height: 220px;
  overflow: hidden;
  background-image: url("../../../Assets/MediaCenter/Activities/headingImg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 35%;
  z-index: 1;

  .headingIcon {
    position: absolute;
    left: 20%;
    top: 0;
    z-index: 4;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: rgba(228, 236, 249, 0.5);
  }
}

.activities-list {
  padding: 50px 20px 6rem;

  .activities-row {
    #{$rtl} & {
      flex-direction: row;
    }
    #{$ltr} & {
      flex-direction: row-reverse;
    }
  }
  .activity-card {
    margin-bottom: 60px;

    .card-img {
      height: 226px;
      overflow: hidden;
      border-radius: 8px 8px 0px 0px;
      box-shadow: -2px 0px 18px rgba(0, 0, 0, 0.38);
      img {
        width: 100%;
      }
    }
    .card-info {
      margin-top: 10px;
      height: 100px;
      display: flex;
      justify-content: space-between;
      #{$rtl} & {
        flex-direction: row;

        a {
          text-decoration: none;
          color: #212529;
        }

        .card-date {
          width: 25%;
          position: relative;
          .dateBox {
            position: absolute;
            bottom: 0;
            right: 20%;
            width: 70px;
            height: 137px;
            border-radius: 8px;
            background: #00927b;
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.19);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem 0 0;

            p {
              margin: 0;
              color: #fff;
              &.day {
                font-size: 24px;
                font-weight: 500;
              }
              hr {
                width: 100%;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
              }
              &.month,
              &.year {
                font-size: 12px;
                font-weight: 500;
              }
            }
          }
        }
        .card-label {
          width: 70%;
          p {
            font-size: 14px;
            font-weight: 700;
            line-height: 29px;
            margin: 0;
          }
        }
      }
      #{$ltr} & {
        flex-direction: row-reverse;

        a {
          text-decoration: none;
          color: #212529;
        }

        .card-date {
          width: 25%;
          position: relative;
          .dateBox {
            position: absolute;
            bottom: 0;
            left: 20%;
            width: 70px;
            height: 137px;
            border-radius: 8px;
            background: #00927b;
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.19);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem 0 0;

            p {
              margin: 0;
              color: #fff;
              &.day {
                font-size: 24px;
                font-weight: 500;
              }
              hr {
                width: 100%;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
              }
              &.month,
              &.year {
                font-size: 12px;
                font-weight: 500;
              }
            }
          }
        }
        .card-label {
          width: 70%;
          p {
            font-size: 14px;
            font-weight: 700;
            line-height: 29px;
            margin: 0;
            direction: ltr;
          }
        }
      }
    }
  }
}
