@import "../../scss/vars.scss";

.header-container {
  padding: 5px 77px 0;

  .header-content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    #{$rtl} & {
      flex-direction: row;
    }

    #{$ltr} & {
      flex-direction: row-reverse;
    }

    .header__main-logo {
      width: fit-content;
    }
    .header__links {
      width: 64%;
      padding: 15px 0;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      border-bottom: 1px solid #b3bcc9;

      #{$rtl} & {
        flex-direction: row;
      }

      #{$ltr} & {
        flex-direction: row-reverse;
      }

      .mobile-nav__toggler {
        display: none;
        svg {
          width: 20px;
        }
      }

      .links-holder {
        display: none;
        .language {
          cursor: pointer;
          span {
            font-size: 14px;
            color: #4e6276;
            margin-inline-start: 6px;
          }
        }
      }

      a {
        font-size: 12px;
        text-decoration: none;
        padding: 10px 0;
        color: #00927b !important;

        &:first-of-type {
          margin-inline-end: 26px;
        }
      }
    }

    .header__minstry-logo {
      width: fit-content;
    }
    .header-socials {
      display: none;
    }
  }
}

@media screen and (max-width: 1120px) {
  .header-container {
    padding: 20px 30px;
    .header__links {
      width: 55%;
    }
  }
}

@media screen and (max-width: 1024.5px) {
  .header-container {
    padding: 20px 30px 0;
    .header-content {
      .header__links {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  .header-container {
    padding: 20px 0 5px;

    .header-content {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .header__main-logo {
        width: 50%;
        #{$rtl} & {
          padding: 0 20px 0 0;
        }

        #{$ltr} & {
          padding: 0 0 0 20px;
        }
        img {
          width: 100%;
        }
      }
      .header__links {
        order: 4;
        padding: 5px 0;
        margin: 10px auto;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        .mobile-nav__toggler {
          display: block;
          svg {
            fill: #00927b;
            width: 30px;
          }
        }
        .links-holder {
          display: block !important;

          a {
            font-size: 14px;
          }
        }
      }
      .header__minstry-logo {
        order: 2;
        #{$rtl} & {
          padding: 0 0 0 20px;
        }

        #{$ltr} & {
          padding: 0 20px 0 0;
        }
      }
      .header-socials {
        order: 3;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        a {
          &:not(:last-of-type) {
            margin-inline-end: 15px;
          }
          svg {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            fill: #fff;
            background-color: #00927b;
            &:not(.youtube) {
              padding: 2.5px;
            }
          }
        }
      }
    }
  }
}
