@import "../../../scss/vars.scss";

.services-container {
  height: 528px;
  padding: 79px 68px;
  margin-bottom: 6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: url("../../../Assets/Homepage/services/services-main-bg.png");
  background-size: cover;
  position: relative;

  #{$rtl} & {
    flex-direction: row;

    .right {
      background-image: url("../../../Assets/Homepage/services/services-bg.png");
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      width: 30%;
      height: 395px;
      padding: 17px 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-radius: 15px;
      z-index: 1;

      h2 {
        color: #efc420;
        font-size: 32px;
        font-weight: 700;
        width: 86px;
        line-height: 59.5px;
        border-bottom: 4px solid #fff;
      }
    }
    .left {
      position: absolute;
      width: 70%;
      left: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 320px;
      padding: 20px;
      border-radius: 15px;
      background: #fff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
      z-index: 3;
      transform: translateX(120px);

      .card-body {
        width: 146px;
        height: 100%;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:not(:last-of-type) {
          margin-inline-end: 20px;
        }

        &:hover {
          cursor: pointer;
          background-color: #efc420;

          p {
            color: #fff;
          }
        }

        img {
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
  #{$ltr} & {
    flex-direction: row-reverse;

    .right {
      background-image: url("../../../Assets/Homepage/services/services-bg.png");
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      width: 30%;
      height: 395px;
      padding: 17px 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      border-radius: 15px;
      z-index: 1;

      h2 {
        color: #efc420;
        font-size: 32px;
        font-weight: 700;
        width: 86px;
        line-height: 59.5px;
        border-bottom: 4px solid #fff;
        direction: ltr;
      }
    }
    .left {
      position: absolute;
      width: 70%;
      right: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 320px;
      padding: 20px;
      border-radius: 15px;
      background: #fff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
      z-index: 3;
      transform: translateX(-120px);

      .card-body {
        width: 146px;
        height: 100%;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:not(:last-of-type) {
          margin-inline-end: 20px;
        }

        &:hover {
          cursor: pointer;
          background-color: #efc420;

          p {
            color: #fff;
          }
        }

        img {
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1400.5px) {
  .services-container {
    .left {
      #{$rtl} & {
        transform: translateX(60px);
      }
      #{$ltr} & {
        transform: translateX(-60px);
      }
      padding: 15px;
      .card-body {
        &:not(:last-of-type) {
          margin-inline-end: 10px !important;
        }
        width: 160px;
      }
    }
  }
}

@media screen and (max-width: 1366.5px) {
  .services-container {
    .left {
      #{$rtl} & {
        transform: translateX(60px);
      }
      #{$ltr} & {
        transform: translateX(-60px);
      }
      padding: 15px;
      .card-body {
        &:not(:last-of-type) {
          margin-inline-end: 10px !important;
        }
        width: 155px;
      }
    }
  }
}

@media screen and (max-width: 1280.5px) {
  .services-container {
    padding: 70px 45px;
    .left {
      #{$rtl} & {
        transform: translateX(40px);
      }
      #{$ltr} & {
        transform: translateX(-40px);
      }
      padding: 15px;
      .card-body {
        &:not(:last-of-type) {
          margin-inline-end: 10px !important;
        }
        width: 145px;
      }
    }
  }
}
@media screen and (max-width: 1024.5px) {
  .services-container {
    padding: 30px 15px;
    .right {
      width: 40%;
    }
    .left {
      width: 75%;
      #{$rtl} & {
        transform: translateX(20px);
      }
      #{$ltr} & {
        transform: translateX(-20px);
      }
      padding: 15px;
      .card-body {
        width: 120px;
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  .services-container {
    height: initial;
    #{$rtl} & {
      flex-direction: column;
      padding: 40px 20px 0;
      margin-bottom: 0;
      .right {
        width: 100%;
        height: 300px;
        justify-content: flex-start;
      }
      .left {
        position: static;
        transform: translateX(0);
        transform: translateY(-100px);
        height: fit-content;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 90%;
        padding: 20px 10px;

        .card-body {
          height: 150px;
          margin: 10px !important;
          padding: 20px 10px 10px;
          width: 40%;
        }
      }
    }
    #{$ltr} & {
      flex-direction: column;
      padding: 40px 20px 0;
      margin-bottom: 0;
      .right {
        width: 100%;
        height: 300px;
        justify-content: flex-start;
      }
      .left {
        position: static;
        transform: translateX(0);
        transform: translateY(-100px);
        height: fit-content;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 90%;
        padding: 20px 10px;

        .card-body {
          height: 150px;
          margin: 10px !important;
          padding: 20px 10px 10px;
          width: 40%;
        }
      }
    }
  }
}
