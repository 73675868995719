@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "../../../scss/vars.scss";

* {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #53b196;
  }

  &::-webkit-scrollbar-track {
    background: #ececec;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: #53b196;
    scrollbar-track-color: #ececec;
  }
}

.main-container {
  padding: 37px 26px;
  background-color: #00927b;
  height: 100vh;

  .emptyData {
    width: 95%;
    // height: 100%;
    text-align: center;
    // top: 30%;
    position: absolute;
    height: 95%;

    img {
      width: 40%;
      margin-bottom: 15px;
    }

    span {
      background-color: #00927b;
      padding: 12px;
      border: 1px solid #aa8e56;
      color: #fff;
      border-radius: 10px;
    }
  }

  .inner-container {
    padding: 0px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // height: 913px;

    .mobile-only {
      display: none;
    }

    .right-side {
      padding-right: 32px;
      width: 45%;
      // height: 100%;

      .activities {
        padding: 0;
        box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.12);

        h1 {
          margin: 0;
          padding: 10px 20px 0 17px;
          font-size: 18px;
          color: #00927b;
          line-height: 40px;
          text-align: left;
        }

        .scores-container {
          background: #fff;
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
          padding: 20px 10px;
          transform: translateX(-4%);
          margin: 0rem 0px 0px;
          width: 116%;
          // min-height: 703px;
          // height: 703px;
          overflow-y: auto;
          height: 65vh;

          .match {
            border-radius: 15px;
            //background: #e4e6e8;
            padding: 5px 8px;
            border: 1px solid #ddd;

            &:not(:last-of-type) {
              margin-bottom: 5px;
            }

            .sides {
              background-color: #dddddd63;
              padding: 10px;
              border-radius: 15px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .side {
                width: 45%;
                padding: 10px;
                border-radius: 10px;
                display: flex;
                align-items: center;

                &.green-bg {
                  // comment this line to view border radius
                  // background-color: #00927b;
                  background-color: #fdfdfd;
                  border: solid 1px #e4e6e8;

                  .side-img {
                    border: 1px solid #00927b;
                    border-radius: 50%;
                  }
                }

                &.yellow-bg {
                  // same here
                  // background-color: #f6c52d;
                  background-color: #fdfdfd;
                  border: solid 1px #e4e6e8;

                  .side-img {
                    border: 1px solid #f6c52d;
                    border-radius: 50%;
                  }
                }

                span {
                  // color: #fff;
                  // font-size: 11px;
                  color: #212529;
                  font-size: 12px;
                }

                .side-img {
                  background-color: #fff;
                  width: 40px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-inline-end: 10px;
                  padding: 2px;

                  img {
                    width: 34px;
                    height: 25px;
                    border-radius: 50px;
                  }
                }
              }

              p {
                margin: 0;
                font-size: 15px;
                font-weight: 700;
              }
            }

            .info-section {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 5px 10px;

              .sport-type {
                flex: 1;
                margin: 0;
                text-align: start;
                font-size: 11px;

                img {
                  width: 40px;
                  border: 2px solid #00927b;
                  padding: 5px;
                  border-radius: 50%;
                  margin-inline-end: 10px;
                }
              }

              .score {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                margin: 0;
                font-size: 17px;
                font-weight: 600;
              }

              .date {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                font-size: 12px;

                img {
                  margin-inline-end: 10px;
                  width: 20px;
                }

                p {
                  text-align: center;
                  margin: 0;
                  font-size: 10px;
                }
              }
            }
          }
        }

        .footer {
          padding: 15px 20px 30px 5px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .label {
            color: #fff;
            font-size: 16px;
            margin: 0;

            i {
              margin-inline-start: 10px;
            }
          }

          .buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .button {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              border-radius: 13px;
              padding: 5px 12px;
              height: 44px;

              p {
                margin: 0;
                font-size: 14px;
              }

              img {
                width: 22px;
                margin-inline-end: 5px;
              }

              &:first-of-type {
                background-color: #00927b;
                color: #fff;
                margin-inline-end: 10px;
                cursor: pointer;
              }

              &:last-of-type {
                color: #a5a2a0;
                border: 1px solid #f2ece7;
                cursor: pointer;
              }
            }
          }
        }
      }

      .logo {
        padding: 0px 0px 0 0;
        text-align: center;

        img {
          width: 50px;
        }
      }
    }

    .left-side {
      padding-left: 32px;
      width: 45%;
      // height: 100%;

      .indicators {
        padding: 0;
        box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.12);

        h1 {
          padding: 10px 20px 0px 0;
          margin: 0;
          font-size: 18px;
          color: #00927b;
          line-height: 40px;
        }

        .meters-container {
          padding: 19px 20px;
          background: #fff;
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
          transform: translateX(17%);
          margin: 0rem 0 0 0;
          width: 116%;
          // height: 703px;
          overflow-y: auto;
          height: 65vh;

          .meter-data {
            display: flex;
            justify-content: space-between;
            align-items: center;

            #{$rtl} & {
              flex-direction: row;
            }

            #{$ltr} & {
              flex-direction: row-reverse;
            }

            &:not(:last-of-type) {
              border-bottom: 1px solid rgba(181, 175, 167, 0.32);
              margin-bottom: 3px;
            }

            .count {
              border: 1px solid #e1e6e4;
              padding: 12px 20px;
              color: #1a1c23;
              font-weight: 500;
              width: 76px;
              text-align: center;
            }

            .meter {
              width: 350px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              padding-bottom: 20px;

              p {
                color: #1a1c23;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
              }

              .body {
                height: 8px;
                width: 100%;
                background: #fff;
                box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1) inset;
                position: relative;

                .fill {
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 100%;

                  &.greenBG {
                    background-color: #00927b;
                  }

                  &.purpleBG {
                    background-color: #9747ff;
                  }

                  &.redBG {
                    background-color: #e54149;
                  }

                  &.blueBG {
                    background-color: #1e6bb4;
                  }

                  &.yellowBG {
                    background-color: #ffce00;
                  }

                  &.orangeBG {
                    background-color: #eb5d14;
                  }

                  &.brownBG {
                    background-color: #aa8e56;
                  }
                }
              }
            }
          }
        }

        .footer {
          padding: 15px 5px 30px 30px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .label {
            color: #fff;
            font-size: 16px;
            margin: 0;

            i {
              margin-inline-start: 10px;
            }
          }

          .buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .button {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              border-radius: 13px;
              padding: 5px 12px;
              cursor: pointer;

              p {
                margin: 0;
                font-size: 14px;
              }

              img {
                width: 25px;
                margin-inline-start: 5px;
              }

              &:first-of-type {
                background-color: #00927b;
                color: #fff;
                margin: 0 6px;
              }

              &:last-of-type {
                color: #a5a2a0;
                border: 1px solid #f2ece7;
              }
            }
          }
        }
      }

      .logo {
        padding: 0px 0 0;
        text-align: center;

        img {
          width: 40px;
          //padding-top: 10px;
        }

        h2 {
          color: #fff;
          margin: 0;
          font-size: 21px;
        }
      }
    }
  }
}

.triangle {
  background-image: linear-gradient(409deg, #00927b 23%, #ffffff 23.1%);
}

.triangle2 {
  background-image: linear-gradient(
    313deg,
    #00927b,
    #00927b,
    #00927b 23%,
    #ffffff 23.1%
  );
}

@media screen and (max-width: 1280.5px) {
  .main-container {
    .inner-container {
      .right-side {
        .logo {
          //padding: 0px 3px 0px 0px;

          img {
            // width: 68%;
          }
        }

        .activities {
          .scores-container {
            .match {
              .info-section {
                .sport-type {
                  img {
                    width: 35px !important;
                    margin-inline-end: 5px;
                  }
                }
              }
            }
          }

          .footer {
            .label {
              width: 40%;
            }
          }
        }
      }

      .left-side {
        .logo img {
          //width: 48%;
        }
      }
    }
  }

  .triangle {
    background-image: linear-gradient(-313deg, #00927b 19%, #ffffff 19.1%);
  }

  .triangle2 {
    background-image: linear-gradient(
      313deg,
      #00927b,
      #00927b,
      #00927b 19%,
      #ffffff 19.1%
    );
  }
}

@media screen and (max-width: 1120px) {
  .main-container {
    padding: 50px 30px;

    .inner-container {
      .right-side {
        padding-right: 0;

        .activities {
          h1 {
            font-size: 18px;
          }

          .scores-container {
            margin: 0rem 0 0;

            .match {
              .info-section {
                .sport-type {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  img {
                    margin-inline-end: 0;
                    margin-bottom: 3px;
                  }
                }
              }
            }
          }
        }
      }

      .left-side {
        padding-left: 0;

        .indicators {
          h1 {
            font-size: 18px;
          }

          .meters-container {
            margin: 0rem 0 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024.5px) {
  .main-container {
    .inner-container {
      padding: 0;

      .right-side {
        padding-right: 0;

        .activities {
          h1 {
            padding: 20px 10px 0 0;
            font-size: 18px;
          }

          .scores-container {
            .match {
              .sides {
                .side {
                  span {
                    font-size: 11px;
                  }
                }
              }

              .info-section {
                padding: 3px 0 0 0;
              }
            }
          }

          .footer {
            padding: 25px 5px 30px;
          }
        }

        .logo {
          padding: 10px 5px 0;

          img {
            width: 75px;
          }
        }
      }

      .left-side {
        padding-left: 0;

        .indicators {
          h1 {
            padding: 20px 10px 0 0;
            font-size: 18px;
          }

          .meters-container {
            .meter-data {
              .meter {
                width: 70%;

                p {
                  font-size: 14px;
                }
              }
            }
          }

          .footer {
            padding: 25px 5px 30px;

            .buttons {
              .boys {
                margin: 0 5px !important;
                cursor: pointer;
              }
            }
          }
        }

        .logo {
          padding: 10px 0 0 10px;

          img {
            width: 35px;
          }

          h2 {
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  .main-container {
    padding: 20px;
    margin-bottom: 80px;

    .inner-container {
      flex-direction: column;
      height: initial;
      padding: 0;

      .right-side {
        width: 100%;
        padding: 0;
        margin-bottom: 2rem;

        .mobile-only {
          display: block;
        }

        .mobile-heading {
          display: flex;
          align-items: flex-end;
          justify-content: space-around;

          img {
            width: 15%;
          }
        }

        .activities {
          padding: 8px 10px;
          box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.12);

          h1 {
            font-size: 18px;
            padding: 0 10px 0 0;
          }

          .scores-container {
            width: 100%;
            margin-top: 10px;
            transform: translateY(0);
            height: 30vh;
            padding-top: 5px;

            .match {
              margin-bottom: 10px;

              .sides {
                flex-direction: column;

                .side {
                  width: 100%;

                  &:first-of-type {
                    margin-bottom: 5px;
                  }
                }

                p {
                  display: none;
                }
              }
            }
          }

          .footer {
            flex-direction: column;
            justify-content: center;
            padding: 7px 10px;

            .label {
              margin-bottom: 8px;
              text-align: center;
              font-size: 16px;
              width: 100%;
            }

            .buttons {
              .boys {
                border: 1px solid #fff;
                margin-inline-end: 20px !important;
              }

              .girls {
                background-color: #fff;
                cursor: pointer;

                p {
                  color: #00927b;
                }
              }
            }
          }
        }

        .logo {
          display: none;
        }
      }

      .left-side {
        width: 100%;
        padding: 0;

        .mobile-heading {
          display: flex;
          align-items: center;
          justify-content: space-around;

          .mobile-only {
            display: block;
            text-align: center;
            width: 20%;

            img {
              width: 50px;
            }

            h2 {
              color: #00927b;
              font-size: 18px;
            }
          }
        }

        .indicators {
          padding: 8px 10px;
          box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.12);

          h1 {
            font-size: 18px;
            padding: 0 10px 0 0;
          }

          .meters-container {
            width: 100%;
            margin-top: 10px;
            transform: translateY(0);
            padding: 8px 10px;
            height: 30vh;

            .meter-data {
              flex-direction: row;
              padding-bottom: 15px;

              .count {
                // width: 100%;
                text-align: center;
                font-size: 12px;
                padding: 5px;
              }

              .meter {
                //width: 100%;
                padding-bottom: 0px;

                p {
                  width: 100%;
                  font-size: 12px;
                }
              }
            }
          }

          .footer {
            flex-direction: column-reverse;
            padding: 20px 10px;

            .label {
              margin-bottom: 15px;
              font-size: 20px;
            }

            .buttons {
              .boys {
                border: 1px solid #fff;
                margin-inline-end: 20px !important;
              }

              .girls {
                background-color: #fff;
                cursor: pointer;

                p {
                  color: #00927b;
                }
              }
            }
          }
        }

        .logo {
          display: none;
        }
      }
    }
  }

  .triangle {
    background-image: linear-gradient(360deg, #00927b 31%, #ffffff 31.1%);
  }

  .triangle2 {
    background-image: linear-gradient(360deg, #00927b 31%, #ffffff 28.1%);
  }

  // .counters-container{
  //   padding: 45px 20px 20px 20px;
  // }
}

.displayb {
  display: block;
}

.displayn {
  display: none;
}

.activeBtnLatest {
  background-color: #00927b !important;
  color: #fff !important;

  p {
    color: #fff !important;
  }
}

.deActiveBtnLatest {
  color: #a5a2a0 !important;
  background-color: #fff !important;
  border: 1px solid #f2ece7 !important;
}

.redp {
  text-align: center;
  border: 1px solid #e4e6e8;
  border-radius: 2px;
  padding: 5px;
  background-color: #f9f9f9;
  font-size: 13px;
}

.redColor {
  // color: red;
}
