@import "../../../scss/vars.scss";

.prints-list {
  padding: 50px 20px 4rem;
  margin-bottom: 5rem;
}

.accordion {
  padding-inline-end: 10px;
}

.accordion-button {
  #{$rtl} & {
    flex-direction: row;

    &::after {
      margin-right: auto;
      margin-left: 0 !important;
    }
  }
  #{$ltr} & {
    flex-direction: row-reverse;

    &::after {
      margin-left: auto;
      margin-right: 0 !important;
    }
  }

  &:not(.collapsed) {
    background-color: #e6e6e6 !important;

    p {
      color: #000;
    }
  }
}

.accordHeading {
  P {
    margin: 0;
  }
}

.accordion-body {
  border: 1px solid #e6e6e6;
  max-height: 400px;
  overflow-y: scroll;

  .prints-row {
    #{$rtl} & {
      flex-direction: row;

      .print-box {
        width: 85%;
        height: 230px;
        border-radius: 25px;
        position: relative;
        display: flex;
        margin: auto auto 30px;

        .info {
          width: 50%;
          background-color: #05555c;
          position: relative;
          border-radius: 0 25px 25px 0;
          padding: 50px 20px;

          h3 {
            color: #fff;
            font-size: 20px;
          }

          button {
            width: 120px;
            padding: 5px 10px;
            background-color: #00bd9f;
            border-radius: 50px;
            position: absolute;
            bottom: 30px;
            right: -50px;
            color: #fff;
          }
        }

        .img {
          width: 50%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 25px 0 0 25px;
          }
        }
      }
    }
    #{$ltr} & {
      flex-direction: row-reverse;

      .print-box {
        width: 85%;
        height: 230px;
        border-radius: 25px;
        position: relative;
        display: flex;
        margin: auto auto 30px;
        flex-direction: row-reverse;

        .info {
          width: 50%;
          background-color: #05555c;
          position: relative;
          border-radius: 25px 0 0 25px;
          padding: 50px 20px;

          h3 {
            color: #fff;
            font-size: 20px;
            direction: ltr;
          }

          button {
            width: 120px;
            padding: 5px 10px;
            background-color: #00bd9f;
            border-radius: 50px;
            position: absolute;
            bottom: 30px;
            left: -50px;
            color: #fff;
          }
        }

        .img {
          width: 50%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 0 25px 25px 0;
          }
        }
      }
    }
  }
}
