@import "../../../scss/vars.scss";

.content {
  text-align: center;
  margin-bottom: 20px;
  
  .main-img {
    margin-bottom: 20px;

    img {
      border-radius: 15px;
      cursor: pointer;
      width: 90%;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      transition: all 0.5s ease-in-out;

      &:hover {
        -webkit-filter: grayscale(0);
        -moz-filter: grayscale(0);
        -o-filter: grayscale(0);
        filter: grayscale(0);
      }
    }
  }

  .meta-data {
    width: 300px;
    text-align: center;
    background-color: #fff;
    margin: auto;
    padding: 10px 20px;
    background: #fff;
    margin-top: -42px;
    position: relative;
    z-index: 1;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.3);

    .sp-data {
      font-family: "Alexandria", sans-serif !important;
      ;
      font-size: 16px !important;
    }

    span {
      padding: 0 10px;
      color: #686a6f;
      font-size: 12px;

      img {
        margin: 0 5px;
      }

      &:first-child {
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
    }
  }

  .title {
    h3 {
      font-size: 20px;
      font-family: "Alexandria", sans-serif;
      line-height: 1.1;
      color: #009f7c;
      margin: 25px auto;
      transition: all 0.4s ease-in-out;
      cursor: pointer;
      width: 90%;
    }
  }

  .description {
    border: 1px solid #eee;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px #eee;
    width: 90%;
    margin: auto;
    //   background-image: url("../images/icons/attach.svg");
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: center center;

    p {
      font-size: 15px;
      font-family: "Alexandria", sans-serif !important;
      color: #6c757d;
      margin: auto;
      margin-bottom: 25px;
      line-height: 28px;
      text-align: justify;

      #{$ltr} & {
       text-align: left;
      }
    }
  }
}

.post-author {
  text-align: right;
  width: 90%;
  margin: 15px auto;
}

.post-author a {
  background-color: #009f7c;
  border: 1px solid #009f7c;
  padding: 15px 25px;
  min-width: 200px;
  color: #fff;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  transition: all .3s ease-in-out;
}