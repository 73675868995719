.photos {
  padding-bottom: 6rem;
}

.grid {
  -webkit-column-count: 4;
  column-count: 4;
  grid-column-gap: 0.5em;
  -webkit-column-gap: 0.5em;
  column-gap: 0.5em;
  max-width: 980px;
  margin: auto;
  width: 100%;

  img {
    height: auto;
    max-width: 100%;
    margin-bottom: 10px;
    cursor: zoom-in;
  }
}
