@import "../../../scss/vars.scss";

.ads-container {
  margin-bottom: 5rem;

  .ads-list{
    padding: 3rem 0;
  }

  .ads-row {
    #{$rtl} & {
      flex-direction: row;
    }
    #{$ltr} & {
      flex-direction: row-reverse;
    }
  }
}

.advertice-box {
  margin: 15px 0;
  position: relative;
  height: 220px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #eee;
  overflow: hidden;

  a {
    position: absolute;
    right: 0;
    top: 0;
    // z-index: 99;
    background: #009f7c;
    padding: 10px;
    border-radius: 0 5px;

    img {
      width: 22px;
    }
  }

  img.main-img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    transition: all 0.5s ease-in-out;
  }
}
