@import "../../../scss/vars.scss";

.counters-container {
  height: 422px;
  display: flex;
  justify-content: center;
  align-items: center;

  #{$rtl} & {
    flex-direction: row;

    .title-card {
      margin-inline-end: 15px;
    }
    .card-outer {
      &:not(:last-of-type) {
        margin-inline-end: 28px;
      }
    }
  }
  #{$ltr} & {
    flex-direction: row-reverse;

    .title-card {
      margin-inline-start: 15px;
    }
    .card-outer {
      &:not(:last-of-type) {
        margin-inline-start: 28px;
      }
    }
  }

  .title-card {
    display: flex;
    width: 174px;
    height: 207px;
    padding: 24px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;

    .img {
      margin-bottom: 17px;
    }
    .label {
      text-align: center;
      p {
        margin: 0;
        color: #00927b;
        font-size: 36px;
        font-weight: 700;
      }
    }
  }
  .card-outer {
    padding: 8px;
    border-radius: 15px;
    border: 2px solid #00927b;
    .card-inner {
      border-radius: 15px;
      background: #00927b;
      display: flex;
      width: 174px;
      height: 207px;
      padding: 24px 7px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      .card-info {
        margin-bottom: 35px;
        color: #fff;
        p {
          margin: 0;
          &:first-of-type {
            font-size: 36px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280.5px) {
  .counters-container {
    .title-card {
      width: 15%;
    }
    .card-outer {
      width: 15%;
      &:not(:last-of-type) {
        margin-inline-end: 15px;
      }
      .card-inner {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  .counters-container {
    height: initial;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    .title-card {
      width: 45%;
      margin: 0;
      .label {
        p {
          font-weight: 300;
        }
      }
    }
    .card-outer {
      width: 45%;
      margin: 0 0 1rem 0 !important;
      .card-inner {
        width: 100%;
        height: 180px;
        padding: 10px 5px;
        .card-info {
          margin-bottom: 10px;
          p {
            &:first-of-type {
              font-size: 30px;
            }
            &:last-of-type {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
