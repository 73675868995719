@import "../../../scss/vars.scss";

.news-container {
  height: 727px;
  background-image: url("../../../Assets/Homepage/News/bg.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .news-inner-container {
    width: 1357px;
    height: 480px;
    padding: 10px;
    display: flex;
    margin-top: 1.5rem;

    #{$rtl} & {
      flex-direction: row;
    }
    #{$ltr} & {
      flex-direction: row-reverse;
    }
  }

  .title {
    width: 15%;
    h1 {
      color: #efc420;
      font-size: 36px;
      text-align: center;
      width: 86px;
      margin: 10px auto;
    }
    hr {
      width: 86px;
      border-top: 4px solid #fff;
      opacity: 1;
      margin: auto;
    }
  }
  .slider-container-ext {
    width: 85%;
  }
}

@media screen and (max-width: 1366px) {
  .news-container {
    .news-inner-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1280.5px) {
  .news-container {
    .news-inner-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024.5px) {
  .news-container {
    .news-inner-container {
      width: 100%;
      #{$rtl} & {
        flex-direction: column;
      }
      #{$ltr} & {
        flex-direction: column;
      }
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        width: 85%;
        margin-bottom: 2rem;

        h1 {
          width: 100%;
          text-align: start;
        }
        hr {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  .news-container {
    height: initial;
    padding: 40px 20px;
    .news-inner-container {
      #{$rtl} & {
        flex-direction: column;
      }
      #{$ltr} & {
        flex-direction: column;
      }
      justify-content: center;
      align-items: center;
      width: 100%;
      height: initial;
      margin-top: 0;
      padding: 0;
      .title {
        margin-bottom: 1.5rem;
        width: 100%;
        h1 {
          width: 100%;
          text-align: start;
        }
        hr {
          margin: 0 0 0 auto;
        }
      }

      .slider-container-ext {
        width: 100%;
      }
    }
  }
}
