@import "../../../../../scss/vars.scss";

.slider-container-internal {
  position: relative;
  .slider-nav {
    position: absolute;
    top: 0;

    display: flex;
    justify-content: flex-end;

    #{$rtl} & {
      left: 21px;
    }
    #{$ltr} & {
      right: 21px;
    }

    .nav-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      border-radius: 8px;
      background: #efc420;
      z-index: 5;
      cursor: pointer;

      &:first-of-type {
        margin-inline-end: 20px;
      }
    }
  }

  .slider-holder {
    .slick-slider .slick-track {
      // width: fit-content !important;

      .slick-slide{
        float: left !important;
      }
    }
    .slide-body {
      display: flex !important;
      align-items: flex-end;
      direction: rtl;
      padding: 10px;
      margin-bottom: 16px;

      #{$rtl} & {
        flex-direction: row;

        .body-text {
          width: 608px;
          height: 349px;
          padding: 10px 30px 25px 15px;
          border-radius: 10px 0px 0px 10px;
          background: #fff;
          transform: translateX(10px);
          overflow: hidden;

          a {
            text-decoration: none;
            color: #212529;
          }
          .slide-title {
            font-size: 23px;
            font-weight: 600;
            // margin-bottom: 0.5rem;
          }
          .slide-content {
            font-size: 11px;
            font-weight: 400;
            line-height: 35.5px;
            height: 90%;
            overflow-y: auto;
            div {
              height: 100%;

              p {
                width: 90%;
                margin-bottom: 0.5rem;
                line-height: 2.5;
              }
              iframe {
                width: 100%;
              }
            }
          }
        }
      }
      #{$ltr} & {
        flex-direction: row-reverse;

        .body-text {
          width: 608px;
          height: 349px;
          padding: 10px 15px 25px 30px;
          border-radius: 10px 0px 0px 10px;
          background: #fff;
          transform: translateX(-10px);
          overflow: hidden;
          direction: ltr;

          a {
            text-decoration: none;
            color: #212529;
          }
          .slide-title {
            font-size: 23px;
            font-weight: 600;
            // margin-bottom: 0.5rem;
          }
          .slide-content {
            font-size: 11px;
            font-weight: 400;
            line-height: 35.5px;
            height: 90%;
            overflow-y: auto;
            div {
              height: 100%;

              p {
                width: 90%;
                margin-bottom: 0.5rem;
                line-height: 2.5;
              }
              iframe {
                width: 100%;
              }
            }
          }
        }
      }

      .body-img {
        z-index: 3;
        border: 0.8rem solid #efc420;
        border-radius: 15px;
        width: 558px;
        height: 400px;
        img {
          width: 100%;
          height: 100%;
          overflow: hidden;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .slick-arrow,
    .slick-prev::before,
    .slick-next::before {
      display: none !important;
    }

    .slick-dots {
      text-align: left;
      padding-left: 20px;

      li {
        margin: 0 3px;
        button::before {
          color: #d9d9d9;
          font-size: 9px;
          opacity: 1;
        }

        &.slick-active button::before {
          color: #efc420;
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024.5px) {
  .slider-container-internal {
    .slider-nav {
      top: -2.5rem;
    }
    .slider-holder {
      #{$rtl} & {
        .slide-body {
          .body-img {
            width: 60%;
            img {
              width: 100%;
            }
          }
          .body-text {
            padding: 20px;
            width: 40%;
            .slide-title {
              font-size: 18px;
            }
            .slide-content {
              line-height: 25px;
            }
          }
        }
      }
      #{$ltr} & {
        .slide-body {
          .body-img {
            width: 60%;
            img {
              width: 100%;
            }
          }
          .body-text {
            padding: 20px;
            width: 40%;
            .slide-title {
              font-size: 18px;
            }
            .slide-content {
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  .slider-container-internal {
    .slider-nav {
      position: initial;
      padding: 0 10px;
    }
    .slider-holder {
      padding-bottom: 10px;
      .slide-body {
        #{$rtl} & {
          flex-direction: column;
          padding: 20px 10px 0;
          margin-bottom: 0;
          align-items: center;

          .body-img {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .body-text {
            width: 99%;
            height: initial;
            padding: 20px;
            transform: translateX(0);
            transform: translateY(-10px);
            border-radius: 0 0 10px 10px;
            .slide-title {
              font-size: 20px;
            }
            .slide-content {
              line-height: 30px;
            }
          }
        }
        #{$ltr} & {
          flex-direction: column;
          padding: 20px 10px 0;
          margin-bottom: 0;
          align-items: center;

          .body-img {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .body-text {
            width: 99%;
            height: initial;
            padding: 20px;
            transform: translateX(0);
            transform: translateY(-10px);
            border-radius: 0 0 10px 10px;
            .slide-title {
              font-size: 20px;
            }
            .slide-content {
              line-height: 30px;
            }
          }
        }
      }
      .slick-dots {
        padding-left: 10px;
      }
    }
  }
}
