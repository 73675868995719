@import "../../../scss/vars.scss";

.videos {
  padding-bottom: 6rem;
}

.videos-row {
  #{$rtl} & {
    flex-direction: row;
  }
  #{$ltr} & {
    flex-direction: row-reverse;
  }
}

.media-box {
  margin: 15px 0;
  position: relative;
  height: 220px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #707070;
  overflow: hidden;

  img.main-img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    transition: all 0.5s ease-in-out;
  }

  .play-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 70px;
    z-index: 99;
    width: 100px;
    opacity: 0.5;
  }
}
